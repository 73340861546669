import { H2 } from "../Text/H2";
import { Sub2 } from "../Text/Sub2";
import { Chip } from "../Chips/Chip";
import { H1 } from "../Text/H1";
import { Spinner } from "../Spinners/Spinner";
import React from "react";



const typeToComponent = (unknownObject) => {
    if(Array.isArray(unknownObject)){
        return(
            unknownObject.map(element => {
                return(
                    <Chip key={element} className="m-1" text={element} icon="fas fa-heart" selected={false} isClickable={false}/>  
                )
            })
        )
    }else if(typeof unknownObject === 'string'){
        return(
            <Sub2 text={unknownObject} />
        )
    }
}

const safePush = (element, array) => {
        if(element){
            if(Array.isArray(element) && element.length > 0){
                element.forEach(e => {
                    array.push(e)
                })
            }
        }
    }
/**
 * 
 * @param {content} param0 a dictionary where the keys are the titles
 * and the values are toe contents
 * @returns 
 */
export function PersonaCard({content, name, age, job, income, id, loading, angle}) {

    const [isLoading, setIsLoading] = React.useState(false)

    const [personaProperties, setPersonaProperties] = React.useState({})

    const [userImage, setUserImage] = React.useState("")



    React.useEffect(() => {
        if(content && typeof content === 'object'){
            setUserImage("https://storage.googleapis.com/persona-finder-images/persona_thumbs/"+id+".jpeg")

            let properties = {}

            console.log(content)

            properties["Demographics & Education"] = [
                content["Age Group"],
            ]
            safePush(content["Family status"], properties["Demographics & Education"])
            safePush(content["Highest educational attainment"], properties["Demographics & Education"])
            if(content.Kinder > 0) properties["Demographics & Education"].push(content.Kinder + " child" + (content.Kinder > 1 ? "ren":""))

            properties["Personality & Goals"] = []
            safePush(content["Personality"], properties["Personality & Goals"])
            safePush(content["Motivation"], properties["Personality & Goals"])

            properties["Interests"] = []
            safePush(content["Interest"], properties["Interests"])

            // remove duplicates and add info on missing data
            Object.keys(properties).forEach(element => {
                properties[element] = [...new Set(properties[element])]
                if(properties[element].length < 1){
                    properties[element] = "No data available"
                }
            })

            setPersonaProperties(properties)

        }
    }, [id,content])

    React.useEffect(() => {
        console.log("props.loading changed to " + loading);
        if(loading){
            setIsLoading(true);
        }else{
            setIsLoading(false);
        }
    }, [loading])

    return (
        <div className="m-2 select-none cursor-default">
            {
                angle > 0 ?
                    <div className="fixed top-64 left-0 w-full z-70 overflow-hidden flex flex-col items-center justify-center" style={{opacity: angle}}>
                        <H1 text="Like!" />
                        <i className={`fas fa-heart fa-5x ${angle >= 1 ? "text-primary" : ""}`} style={{transform: "scale("+(angle >= 1 ? 1 : angle)+")"}} ></i>
                    </div>
                :
                    <div className="fixed top-64 left-0 w-full z-70 overflow-hidden flex flex-col items-center justify-center" style={{opacity: angle === 0 ? 0 : -1*angle}}>
                        <H1 text="Nope!" />
                        <i className={`fas fa-times fa-5x ${-1*angle >= 1 ? "text-primary" : ""}`} style={{transform: "scale("+(-1*angle >= 1 ? 1 : angle)+")"}} ></i>
                    </div>
            }
            <div  style={{opacity: angle < 0 ? 1+angle : 1-angle, transform: "translateX("+angle*100+"px) rotate("+angle*10+"deg)", transformOrigin: (angle < 0 ? "bottom left" : "bottom right")}}>
                {
                    isLoading ? <Spinner className="fixed top-64 left-0 w-full z-50 overflow-hidden flex flex-col items-center justify-center" /> : null
                }
                <div className={`${isLoading ? "filter blur-sm" : ""} z-1 h-200 grid grid-cols-1 w-100 gap-0 divide-y divide-secondaryUltraLight max-w-sm rounded-lg overflow-hidden shadow-lg  border-secondary border-b-4 border-2`}>
                    <div className="p-4 items-center flex flex-row bg-secondaryExtremLight">
                        <img className="w-14 h-14 rounded-full lg:w-16 lg:h-16" src={userImage} alt="" />
                        <div className="ml-4">
                            <H2 text={name + " (" + age + ")"} position="text-left" />
                            <Sub2 text={job + " - " + income} position="text-left" />
                        </div>
                    </div>
                    {
                        (typeof personaProperties === 'object') ?
                            Object.keys(personaProperties).map(value => {
                                if(value && personaProperties[value]){
                                    return (
                                        <div key ={value} className="p-4 flex-col flex items-start">
                                            <H2 text={value} />
                                            <div className="m-1"/>
                                            <div className="flex items-start flex-row flex-wrap">
                                                { typeToComponent(personaProperties[value]) }
                                            </div>
                                        </div>
                                    )
                                }else{
                                    return null
                                }
                                
                            })
                        : null
                    }

                </div>
            </div>
        </div>
    )
}