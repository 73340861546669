import { H2 } from "../Text/H2";
import { Sub2 } from "../Text/Sub2";



export function Card(props) {

    return (
        <div class="grid grid-cols-1 gap-2 divide-y divide-secondaryUltraLight max-w-sm rounded-lg overflow-hidden shadow-lg  border-secondary border-b-4 border-2">
            <div className="p-4">
                <H2 text="Headline"/>
                <Sub2 text="This is a text"/>
            </div>
            <div className="p-4">
                <H2 text="Headline"/>
                <Sub2 text="This is a text"/>
            </div>
            <div className="p-4">
                <H2 text="Headline"/>
                <Sub2 text="This is a text"/>
            </div>
            <div className="p-4">
                <H2 text="Headline"/>
                <Sub2 text="This is a text"/>
            </div>
            <div className="p-4">
                <H2 text="Headline"/>
                <Sub2 text="This is a text"/>
            </div>
            <div className="p-4">
                <H2 text="Headline"/>
                <Sub2 text="This is a text"/>
            </div>

        </div>
    )
}