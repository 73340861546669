import developUndraw from '../../../developUndraw.svg';

import React, { useState } from 'react';
import { submitEvent } from '../../../Components/Backend/Backend';




export default function AdInfo({setShowAdInfo}){

    
    const [showModal, setShowModal] = useState(true);
  

return(
    <div>

        {showModal ? 
        <div>

        
       

        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        
            
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        
        
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div className="flex flex-col  justify-center items-center">
            <img src={developUndraw} alt="Swipe your audience" className="flex jusitfy-center w-1/2 h-1/2"/>
            
                <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Advertising feature under development
                </h3>
                <div className="mt-2">
                    
                    <p className="text-sm text-gray-500 m-2">
                        We are currently developing the advertising feature. If you are intrested in testing or later using this feature request access to beta feature. 
                    </p>
           
                </div>
                </div>
            </div>
            

            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button onClick={()=> {setShowModal(false);submitEvent("explain_ad_module","request_access");document.location.href='/request'}} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primaryLight focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:col-start-2 sm:text-sm">
                Request Access
                </button>
                <button onClick={()=> {setShowModal(false);setShowAdInfo(false);submitEvent("explain_ad_module","closed_modal")}} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:col-start-1 sm:text-sm">
                Cancel
                </button>
            </div>
            </div>
        </div>
        </div>
    
         </div>

        : ""}
    </div>
    )

}