export function Footer(props) {
    return(            <footer className="z-40 bg-secondary mt-16 lg:mt-32" aria-labelledby="footer-heading">
    <h2 id="footer-heading" className="sr-only">Footer</h2>
    <div className="max-w-7xl mx-auto pb-10 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="mt-1 grid grid-cols-3 gap-4 lg:gap-8 xl:mt-0 xl:col-span-1">
                <div className="md:grid md:grid-cols-2 gap-2 lg:gap-8">
                    <div className="mt-4 lg:mt-0">
                        <h3 className="text-sm lg:text-md font-semibold text-white tracking-wider">
                        Support
                        </h3>
                        <div className="mt-4 space-y-4">
                            <div>
                                <a href="/feedback" className="text-2sm lg:text-md font-thin text-white hover:text-secondaryLight">
                                    Give Feedback
                                </a>
                            </div>
                            <div>
                                <a href="/request" className="text-2sm lg:text-md font-thin text-white hover:text-secondaryLight">
                                    Request Access
                                </a>
                            </div>
                            
             
                        </div>
                    </div>
                </div>
                <div className="lg:grid lg:grid-cols-2 grid-cols-2 gap-2 lg:gap-8">
                    <div className="mt-4 lg:mt-0">
                        <h3 className="text-sm lg:text-md font-semibold text-white tracking-wider">
                        Legal
                        </h3>
                        <div className="mt-4 space-y-4">
                        <div>
                            <a href="/imprint" className="text-2sm lg:text-md font-thin text-white hover:text-secondaryLight">
                            Imprint
                            </a>
                        </div>

                        <div>
                            <a href="/privacy" className="text-2sm lg:text-md font-thin text-white hover:text-secondaryLight">
                            Privacy
                            </a>
                        </div>

                        <div>
                            <a href="/terms" className="text-2sm lg:text-md font-thin text-white hover:text-secondaryLight">
                            Terms
                            </a>
                        </div>
                        
                       
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div className="mt-6 lg:mt-12 border-t border-secondaryLight pt-4 lg:pt-8">
        <p className="text-2sm lg:text-md font-thin text-white">
            &copy; 2021 Stellwerk3 GmbH, All rights reserved.
        </p>
        </div>
    </div>
    </footer>
)
}