import swipeSvg from '../../../swipeUndraw.svg';
import React, { useState } from 'react';
import { submitEvent } from '../../../Components/Backend/Backend';




export default function Explainer(){

    
    const [showModal, setShowModal] = useState(true);
    //const [showConditions, setShowConditions] = useState(false);
    const [showConditions, ] = useState(false);
    const [showError, setShowError] = useState(false)
    const [answer5, setAnswer5] = useState(false);


return(
    <div>

        {showModal ? 
        <div>

        
        {showConditions ? 
        
        
        ""
        
        :

        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        
            
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        
        
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div className="flex flex-col  justify-center items-center">
            <img src={swipeSvg} alt="Swipe your audience" className="flex jusitfy-center w-1/2 h-1/2"/>
            
                <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    How AudienceDeck works
                </h3>
                <div className="mt-2">
                    
                    <p className="text-sm text-gray-500 m-2">
                        Profiles of potential customers are suggested to you.
                    </p>
                    <p className="text-sm text-gray-500 m-2">
                    Loading the first profile may take some time - please just wait.
                    </p>
                    <p className="text-sm text-gray-500 m-2">
                        If a suggested profile is similar to your desired target group, swipe the profile to the right or click the "Like" button. If the profile does not match your desired target group, swipe the profile to the left or click the "Nope" button. Depending on your swipe behavior, other profiles will be suggested to you.
                    </p>
                    <p className="text-sm text-gray-500 m-2">
                        As soon as our algorithm has detected a valid pattern in your swipe behavior, it will contact you and suggest a first target group (on average after 25 swipes). Afterwards you can decide if you want to refine this target group or if you want to start from scratch. 
                    </p>
                </div>
                </div>
            </div>
            <p className="text-sm text-gray-800 mt-5">To use the service, you must agree to the terms of service:</p>
            <div className="mt-4 relative flex items-start">
                
                <div className="flex items-center h-5">
                        <input onChange={(e)=> {setAnswer5(!answer5)}} value={answer5} id="dataprocted" aria-describedby="offers-description" name="offers" type="checkbox" className="focus:ring-primary h-4 w-4 text-secondary border-primary rounded"/>
                    </div>
                    
                    <div className="ml-3 text-sm">
                        <label htmlFor="offers" className="block text-2sm font-thin text-secondary">I agree to the <a className="underline" href="/terms">terms of service</a>.</label>
                        {showError?
                        <label htmlFor="offers" className="block text-4sm font-thin text-red-500">You have to accept the terms of service to use the service</label>
                        : "" }
                    </div>

                </div>

            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button onClick={()=> {console.log(answer5);if(answer5 === true){setShowModal(false);submitEvent("explain_module","accepted_terms_and_started")}else{setShowError(true);}}} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primaryLight focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:col-start-2 sm:text-sm">
                Next
                </button>
                <button onClick={()=> {window.location = '/'}} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:col-start-1 sm:text-sm">
                Cancel
                </button>
            </div>
            </div>
        </div>
        </div>
    }
         </div>

        : ""}
    </div>
    )

}