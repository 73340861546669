import { H1 } from "../Text/H1";
import { ButtonInputLg } from "../Buttons/ButtonInputLg";



export function MatchCard(props) {

    return (
        <div className="h-96 grid grid-cols-1 gap-2 divide-y divide-secondaryUltraLight max-w-sm rounded-lg overflow-hidden shadow-lg mt-10 border-secondary border-b-4 border-2">
            <div className="flex justify-center flex-col m-4">
                <H1 text="Matching Target Group Found!"/>
                <div className="flex space-x-8 justify-between mt-8">
                    <ButtonInputLg text="Show target groups" color = "secondary" colorHover ="secondaryLight" onClick={ props.showMatches } />
                    <ButtonInputLg text="Continue swiping" color = "secondary" colorHover ="secondaryLight" onClick={ props.continueSwiping } />
                </div>
            </div>
        </div>
    )
}