
export function H1(props) {
    var text = ""
    if (props.children){
        text = props.children
    }else{
        text = props.text
    }

    return (
        <div className={`break-words z-10 font-sans text-4xl font-black text-secondary ${props.position}`}>
            {text}
        </div>
    )
}