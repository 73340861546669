
export function Sub3(props) {

    var text = ""
    if (props.children){
        text = props.children
    }else{
        text = props.text
    }

    var words =text.split(' ')
    var highlight_index = props.highlight;

    if (highlight_index === undefined){
        highlight_index = [words.length];
    }
    
    return (
        <p className={`font-sans text-4sm font-thin ${props.position}`} >
            <span className={`text-secondary`}>
                {words.slice(0,Math.min.apply(Math, highlight_index)).join(" ")}
            </span>
            <span className="text-primary">
                {" " + words.slice(Math.min.apply(Math, highlight_index), Math.max.apply(Math, highlight_index)+1).join(" ")}
            </span>
            <span className="text-secondary">
                {" " + words.slice(Math.max.apply(Math, highlight_index)+1, words.length).join(" ")}
            </span>

        </p>
    )
}