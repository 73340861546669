import React, { useState, useEffect } from 'react';
import { H1 } from '../../Components/BasicComponents/Text/H1';
import { H2 } from '../../Components/BasicComponents/Text/H2';
import { HeaderNavigation } from '../../Components/UI/HeaderNavigation';
import { Footer } from '../../Components/UI/Footer';
import { ButtonInputLgIcon } from '../../Components/BasicComponents/Buttons/ButtonInputLgIcon';
import { sendRequest } from '../../Components/Backend/Backend';
import { Sub1 } from '../../Components/BasicComponents/Text/Sub1';

export default function FeedbackForm(){

    const [answer1, setAnswer1] = useState("");
    const [answer2, setAnswer2] = useState("");
    const [answer3, setAnswer3] = useState("");
    const [answer4, setAnswer4] = useState("");
    const [answer5, setAnswer5] = useState("");
    const [answer6, setAnswer6] = useState("");
    const [answer7, setAnswer7] = useState("");
    const [answer8, setAnswer8] = useState("");

    const [sent, setSent] = useState(false)
    //const [dummy, setDummy] = useState(false)
    const [, setDummy] = useState(false)

   
    const [response, setResponse] = React.useState(null);


    const sendData = function(){
        var data = [answer1, answer2,answer3,answer4,answer5,answer6,answer7,answer8]

        let dataRequest = {
            "method": "POST",
            "url": "https://audiencefinder-backend-p7inyw7pqq-ey.a.run.app/feedback",
          
            "data": {
                "token": localStorage.getItem('token'),
                "data": data
            }
        }
        sendRequest(dataRequest, setDummy, setResponse);
    
        
    }

    useEffect(() => {
        if(response){
            setSent(true)
        }
    }, [response])

    return(
        <div>
            <HeaderNavigation className="z-50"/>

            <div className="z-0 flex justify-center w-full">


                <div class="p-1 lg:p-8 bg-white mt-8 lg:mt-32 w-full lg:w-1/2 m-2 lg:m-0 lg:w-1/2 h-2/3 grid grid-cols-1 gap-2 divide-secondaryUltraLight rounded-lg overflow-hidden shadow-lg border-secondary border-b-4 border-2">
                       
                   <div>
                        <H1 text = "Your Feeback"/>
                        <Sub1 text="We are happy about every feedback. You do not have to answer every question. "/>
                        <div className="mt-8"/>
                            {sent ? 
                            <div className="mt-16 flex flex-col items-center justify-center">
                                <i className="text-secondary text-4xl fas fa-rocket mb-4"></i>
                                <H2 text = "Thank you so much!!"/>
                            </div>
                             :
                            <div>
                                <div>
                                    <label for="comment" class="block text-sm font-medium text-secondary">1. What challenges are you trying to solve?</label>
                                    <div class="mt-1">
                                        <textarea onChange={(e)=> {setAnswer1(e.target.value)}} value={answer1} rows="4" name="comment" id="comment" class=" ring-transparent shadow-sm ring-2 ring-primary focus:ring-primary focus:border-primary block w-full text-2sm font-thin text-secondary p-1 rounded-md"></textarea>
                                    </div>
                                </div>
                                <div className="mt-4"/>
                                <div>
                                    <label for="comment" class="block text-sm font-medium text-secondary">2. What is your main goal for using this SwipeDeck?</label>
                                    <div class="mt-1">
                                        <textarea onChange={(e)=> {setAnswer2(e.target.value)}} value={answer2} rows="4" name="comment" id="comment" class=" ring-transparent shadow-sm ring-2 ring-primary focus:ring-primary focus:border-primary block w-full text-2sm font-thin text-secondary p-1 rounded-md"></textarea>
                                    </div>
                                </div>
                                <div className="mt-4"/>
                                <div>
                                    <label for="comment" class="block text-sm font-medium text-secondary">3. Have you used a similar service before? If yes, what services did you use?</label>
                                    <div class="mt-1">
                                        <textarea onChange={(e)=> {setAnswer3(e.target.value)}} value={answer3} rows="4" name="comment" id="comment" class=" ring-transparent shadow-sm ring-2 ring-primary focus:ring-primary focus:border-primary block w-full text-2sm font-thin text-secondary p-1 rounded-md"></textarea>
                                </div>
                                </div>
                                <div className="mt-4"/>
                                <div>
                                    <label for="comment" class="block text-sm font-medium text-secondary">4. What do you like least?</label>
                                    <div class="mt-1">
                                        <textarea onChange={(e)=> {setAnswer4(e.target.value)}} value={answer4} rows="4" name="comment" id="comment" class=" ring-transparent shadow-sm ring-2 ring-primary focus:ring-primary focus:border-primary block w-full text-2sm font-thin text-secondary p-1 rounded-md"></textarea>
                                </div>
                                </div>
                                <div className="mt-4"/>
                                <div>
                                    <label for="comment" class="block text-sm font-medium text-secondary">5. What do you like most?</label>
                                    <div class="mt-1">
                                        <textarea onChange={(e)=> {setAnswer5(e.target.value)}} value={answer5} rows="4" name="comment" id="comment" class=" ring-transparent shadow-sm ring-2 ring-primary focus:ring-primary focus:border-primary block w-full text-2sm font-thin text-secondary p-1 rounded-md"></textarea>
                                </div>
                                </div>
                                <div className="mt-4"/>
                                <div>
                                    <label for="comment" class="block text-sm font-medium text-secondary">6. Where did you first hear about us?</label>
                                    <div class="mt-1">
                                        <textarea onChange={(e)=> {setAnswer6(e.target.value)}} value={answer6} rows="4" name="comment" id="comment" class=" ring-transparent shadow-sm ring-2 ring-primary focus:ring-primary focus:border-primary block w-full text-2sm font-thin text-secondary p-1 rounded-md"></textarea>
                                </div>
                                </div>
                                <div className="mt-4"/>
                                <div>
                                    <label for="comment" class="block text-sm font-medium text-secondary">7. What’s the ONE thing our service is missing?</label>
                                    <div class="mt-1">
                                        <textarea onChange={(e)=> {setAnswer7(e.target.value)}} value={answer7} rows="4" name="comment" id="comment" class=" ring-transparent shadow-sm ring-2 ring-primary focus:ring-primary focus:border-primary block w-full text-2sm font-thin text-secondary p-1 rounded-md"></textarea>
                                </div>
                                </div>
                                <div className="mt-4"/>
                                <div>
                                    <label for="comment" class="block text-sm font-medium text-secondary">8. Something else you want to tell us?</label>
                                    <div class="mt-1">
                                        <textarea onChange={(e)=> {setAnswer8(e.target.value)}} value={answer8} rows="4" name="comment" id="comment" class=" ring-transparent shadow-sm ring-2 ring-primary focus:ring-primary focus:border-primary block w-full text-2sm font-thin text-secondary p-1 rounded-md"></textarea>
                                </div>
                                </div>
                                <div className="w-full flex justify-end mr-4 mt-10">
                                    <ButtonInputLgIcon onClick={sendData} text ="Send Feedback" icon="fas fa-paper-plane" color ="secondary"/>
                                </div>
                            </div>
                            }
                            
                            
                    </div>
                
                </div>    

            </div>
                
            <Footer/>         

        </div>
    )
}