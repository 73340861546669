import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { Login } from './Components/UI/Login'
import Homepage from './Sites/Homepage/Homepage'
import Swiper from './Sites/Swiper/Swiper'
import UiGallery from './Sites/UiGallery/UiGallery';
import Impressum from './Sites/Legal/Impressum';
import Datenschutz from './Sites/Legal/Datenschutz';
import FeedbackForm from './Sites/Formulars/FeedbackForm';
import RequestForm from './Sites/Formulars/RequestForm';
import BackgroundGallery from './Sites/UiGallery/BackgroundGallery';
import Terms from './Sites/Legal/Terms';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login/>} />

        <Route path="/" element={<Homepage />} />

        <Route path="/request" element={<RequestForm />} />

        <Route path="/feedback" element={<FeedbackForm />} />

        <Route path="/imprint" element={<Impressum />} />

        <Route path="/privacy" element={<Datenschutz />} />

        <Route path="/terms" element={<Terms />} />

        <Route path="/ui" element={<UiGallery />} />

        <Route path="/bg-ui" element={<BackgroundGallery />} />

        <Route path="/app" element={<Swiper />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
