

export async function Post(url, data){
    let req = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }
    return await fetch(url, req);
}

export async function Get(url, data){
    let dataUrl = url + '?' +  Object.keys(data).map(function(k) {return (k) + '=' + (data[k]);}).join('&');
    try {
        return await fetch(dataUrl);
    } catch(e) {
        console.log(e);
        return JSON.stringify({"ok": false, "status": "error", "message": e});
    }
}

export async function PostForm(url, data){

    const formData = new FormData()
    for (const key in data) {
        formData.append(key, data[key])
    }

    let req = {
        method: 'POST',
        headers: {
        },
        body: formData
    }
    return await fetch(url, req);
}

/**
 * Send a request to a backend API
 * 
 * Parameters:
 * - requestData: The data to send to the backend API. Expected to be a JSON object that contains 
 *                the following properties:
 *                - url: The URL to send the request to.
 *                - method: The HTTP method to use.
 * - setIsLoading: a function that will be called with parameter "true" after a request was sent and with "false" after a request errored or finished.
 * - setRawRequest: a function that will be called with the raw request object after a request was sent.
 * - setResponseData: a function that will be called with the response data after a request was answered.
 * 
 */
export async function sendRequest(requestData, setIsLoading, setResponseData) {
    try {
        setIsLoading(true);
        if(requestData.method === 'GET') {
            Get(requestData.url, requestData.data).then(response => {
                if((typeof response == "object") && (response.ok)) {
                    response.json().then(data => {
                        setResponseData(data);
                    });
                } else if(typeof response == "string") {
                    setResponseData({'status': response.status, 'text': response});
                }
            })
        } else if(requestData.method === 'POST') {
            let response = await Post(requestData.url, requestData.data);
            if(response.ok) {
                setResponseData(await response.json());
            } else {
                setResponseData({'status': response.status, 'text': await response.text()});
            }
        } else if(requestData.method === 'FORM') {
            let response = await PostForm(requestData.url, requestData.data);
            if(response.ok) {
                setResponseData(await response.json());
            } else {
                setResponseData({'status': response.status, 'text': await response.text()});
            }
        } else {
            throw new Error('Unsupported method: ' + requestData.method);
        }
        setIsLoading(false);
    } catch (e) {
        console.log(e);
        setIsLoading(false);
    }
}

/**
 * Send a request to a backend API
 * 
 * Parameters:
 * - requestData: The data to send to the backend API. Expected to be a JSON object that contains 
 *                the following properties:
 *                - url: The URL to send the request to.
 *                - method: The HTTP method to use.
 * - setIsLoading: a function that will be called with parameter "true" after a request was sent and with "false" after a request errored or finished.
 * - setRawRequest: a function that will be called with the raw request object after a request was sent.
 * - setResponseData: a function that will be called with the response data after a request was answered.
 * 
 */
export async function submitEvent(context, event) {
    const service="audiencedeck"
    const eventWatchUrl = "https://event-watch-p7inyw7pqq-ey.a.run.app/submit"

    var ewnb = ""
    if (!localStorage.getItem("ewnb")){
        var dt = new Date().getTime();
        var t = 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxyxxxxxxx'.replace(/[xy]/g, function(c) {
                                                                var r = ((dt + Math.random()*16)%16) | 0;
                                                                dt = Math.floor(dt/16);
                                                                return (c==='x' ? r : ((r & 0x3) | 0x8)).toString(16);
                                                            });


        localStorage.setItem("ewnb",t)
    }

    ewnb = localStorage.getItem("ewnb")
    
    //console.log(service)
    //console.log(context)
    //console.log(event)
    //console.log(ewnb)

    const data ={"service": service,
           "context": context,
           "event": event,
            "uuid": ewnb}

    let response = await Post(eventWatchUrl, data);
    console.log(event)
    if(response.ok) {
        //console.log(event)
    } else {
        console.log({'status': response.status, 'text': await response.text()});
    }

}