import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import BackgroudAnimation from '../../Sites/UiGallery/BackgroundGallery';

import { sendRequest } from '../Backend/Backend';
import { H1 } from '../BasicComponents/Text/H1';

import { TextInput } from "../BasicComponents/TextInput";
import { Footer } from './Footer';
import { HeaderNavigation } from './HeaderNavigation';
import SendRequestButton from './SendRequestButton';


export function Login(props) {

    const history = useNavigate();

    const [loginValues, setLoginValues] = useState({});
    const [responseData, setResponseData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isWrongLogin, setIsWrongLogin] = useState(false);

    const updateLoginValues = (id, value) => {

        setLoginValues(prevState => ({
            ...prevState,
            [id]: value
        }))

        
    };

    const submitData = (e) => {
        e.preventDefault();

        let requestData = {
            "url": "https://security-api-p7inyw7pqq-ey.a.run.app/login",
            "method": "POST",
            "data": loginValues
        }

        sendRequest(requestData, setIsLoading, setResponseData)
    };

    useEffect(() => {
        
        // isLoading is used to trigger effect
        // same response 401 would not trigger
        if(responseData !== null && isLoading === false){

            if (responseData["valid"]){
                localStorage.setItem("token", responseData["token"]);
                history(-1);
            }else{
                document.getElementById("login-form").reset();
                setIsWrongLogin(true);
            }   

        }
        

    }, [responseData, isLoading, history]);

    return(
        <div className="static">
            
            <BackgroudAnimation/>
            <div className="flex flex-col justify-center">
                <HeaderNavigation/>
            
                <div className="flex justify-center z-50 mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div>
                        <div class="m-4 lg:m-0 grid grid-cols-1 bg-white p-4 gap-2 divide-y divide-secondaryUltraLight max-w-lg rounded-lg overflow-hidden shadow-lg  border-secondary border-b-4 border-2">
                            <div className="mx-w-lg sm:mx-auto sm:max-w-md">
                        
                                <H1 text = "Login"/>
                                <div className="mr-96"/>
                
                                <p className="mt-2 text-start text-sm text-gray-600">
                                    {"or "} 
                                    <a href="mailto:datascience@stellwerk3.de?subject=Zugang zur Toolbox" className="font-medium text-gray-600 hover:text-primary">
                                    contact us for access
                                    </a>
                                </p>
                            </div>

                            <form id="login-form" className="flex flex-col gap-4 mt-5" onSubmit={(e) =>  {submitData(e)}}>
                                <TextInput name ="Mail" saveId="mail" placeholder = "" onChangeValue={updateLoginValues}/>
                                <TextInput name ="Password" saveId="password" type="password" placeholder = "" onChangeValue={updateLoginValues}/>
                                {isWrongLogin ? 
                                    <div>
                                        <p className="mt-2 text-center text-sm text-accentTwo">
                                            {"Wrong access data, please try again "}

                                            <a href="mailto:datascience@stellwerk3.de?subject=Request access" className="text-sm text-accentTwo hover:text-primary">
                                            contact us for access
                                            </a>
                                        </p>
                                        
                                    </div> : ""
                                }
                                <SendRequestButton name ="Login" type="submit" spinning={isLoading}/>
                            </form>
                            
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
           

        </div>
    )

}