
export function H3(props) {

    var text = ""
    if (props.children){
        text = props.children
    }else{
        text = props.text
    }

    
    return (
        <div className={`font-sans text-2sm font-extrabold text-secondary ${props.position}`}>
            {text}
        </div>
    )
}