import React from 'react';
import { ButtonInputLg } from '../../Components/BasicComponents/Buttons/ButtonInputLg';
import { ButtonInputLgIcon } from '../../Components/BasicComponents/Buttons/ButtonInputLgIcon';
import { ButtonInputMd } from '../../Components/BasicComponents/Buttons/ButtonInputMd';
import { ButtonInputMdIcon } from '../../Components/BasicComponents/Buttons/ButtonInputMdIcon';
import { ButtonInputSm } from '../../Components/BasicComponents/Buttons/ButtonInputSm';
import { ButtonInputSmIcon } from '../../Components/BasicComponents/Buttons/ButtonInputSmIcon';
import { Card } from '../../Components/BasicComponents/Cards/Card';
import { Chip } from '../../Components/BasicComponents/Chips/Chip';
import { Spinner } from '../../Components/BasicComponents/Spinners/Spinner';
import { H1 } from '../../Components/BasicComponents/Text/H1';
import { H2 } from '../../Components/BasicComponents/Text/H2';
import { H3 } from '../../Components/BasicComponents/Text/H3';
import { Sub1 } from '../../Components/BasicComponents/Text/Sub1';
import { Sub2 } from '../../Components/BasicComponents/Text/Sub2';
import { Sub3 } from '../../Components/BasicComponents/Text/Sub3';
import { ReactComponent as Logo } from '../../logo.svg';

export default function UiGallery(){
    return(
        <div className="flex flex-col m-4 gap-4">
            {/* Force tailwind to keep unused colors */}
            <div className="bg-secondary ring-secondary hidden"></div>
            <div className="bg-primary ring-primary hidden"></div>
            <div className="bg-primaryLight ring-primaryLight hidden"></div>
            <div className="bg-primaryUltraLight ring-primaryUltraLight hidden"></div>
            <div className="bg-secondaryLight ring-secondaryLight hidden"></div>
            <div className="bg-secondaryUltraLight ring-secondaryUltraLight hidden"></div>
            <div className="bg-secondaryExtremLight ring-secondaryExtremLight hidden"></div>

            <div>
                <H1 text = "Headline 1 - 78px - #282828 "/>
                <Sub1 text = "Subheader 1 - 28px - #282828 | #C7481F" highlight={[7,8]}/>
            </div>
            <div className="m-2"/>
            <div >
                <H2 text ="Headline 2 - 24px - #282828"/>
                <Sub2 text = "Subheader 2 - 16px - #636363 | #C7481F" highlight={[7,8]}/>
            </div>
            <div className="m-2"/>
            <div >
                <H3 text ="Headline 3 - 18px - #282828"/>
                <Sub3 text = "Subheader 2 - 16px - #636363 | #C7481F" highlight={[7,8]}/>
            </div>
                <div className="m-2"/>
            <div >

            </div>
            <div className="m-2"/>
            <div className="flex-col">
                <ButtonInputLg text ="Button A" color = "secondary" colorHover ="secondaryLight"/>
                <div className="m-2"/>
                <ButtonInputLg text ="Button B" color = "primary" colorHover ="primaryLight"/>     
                <div className="m-2"/>
                <ButtonInputLg text ="Button C" color = "secondaryLight" isDisabled ={true}/>             
            </div>
            <div className="m-2"/>
            <div className="flex-col">
                <ButtonInputMd text ="Button A" color = "secondary" colorHover ="secondaryLight"/>
                <div className="m-2"/>
                <ButtonInputMd text ="Button B" color = "primary" colorHover ="primaryLight"/>     
                <div className="m-2"/>
                <ButtonInputMd text ="Button C" color = "secondaryLight" isDisabled ={true}/>             
            </div>
            <div className="m-2"/>
            <div className="flex-col">
                <ButtonInputSm text ="Button A" color = "secondary" colorHover ="secondaryLight"/>
                <div className="m-2"/>
                <ButtonInputSm text ="Button B" color = "primary" colorHover ="primaryLight"/>     
                <div className="m-2"/>
                <ButtonInputSm text ="Button C" color = "secondaryLight" isDisabled ={true}/>             
            </div>
            <div className="m-2"/>
            <div className="flex-col">
                <ButtonInputLgIcon text ="Button A" color = "secondary" colorHover ="secondaryLight" icon="fas fa-check"/>
                <div className="m-2"/>
                <ButtonInputLgIcon text ="Button B" color = "primary" colorHover ="primaryLight" icon="fas fa-check"/>     
                <div className="m-2"/>
                <ButtonInputLgIcon text ="Button C" color = "secondaryLight" isDisabled ={true} icon="fas fa-check"/>             
            </div>
            <div className="m-2"/>
            <div className="flex-col">
                <ButtonInputMdIcon text ="Button A" color = "secondary" colorHover ="secondaryLight" icon="fas fa-check"/>
                <div className="m-2"/>
                <ButtonInputMdIcon text ="Button B" color = "primary" colorHover ="primaryLight" icon="fas fa-check"/>     
                <div className="m-2"/>
                <ButtonInputMdIcon text ="Button C" color = "secondaryLight" isDisabled ={true} icon="fas fa-check"/>             
            </div>
            <div className="m-2"/>
            <div className="flex-col">
                <ButtonInputSmIcon text ="Button A" color = "secondary" colorHover ="secondaryLight" icon="fas fa-check"/>
                <div className="m-2"/>
                <ButtonInputSmIcon text ="Button B" color = "primary" colorHover ="primaryLight" icon="fas fa-check"/>     
                <div className="m-2"/>
                <ButtonInputSmIcon text ="Button C" color = "secondaryLight" isDisabled ={true} icon="fas fa-check"/>             
            </div>

            <div className="m-2"/>
            <div className="m-2"/>
            <div className="flex-col">
                <Chip text="Chip A" icon="fas fa-heart" selected={false} isClickable={false}/>  
                <div className="m-2"/>
                <Chip text="Chip A (clickable)" icon="fas fa-heart" selected={false} isClickable={true}/>  
                <div className="m-2"/>
                <Chip text="Chip C" icon="fas fa-heart" selected={true} isClickable={false}/>   
                <div className="m-2"/>
                <Chip text="Chip D (clickable)" icon="fas fa-heart" selected={true} isClickable={true}/>   
            </div>

            <div className="m-2"/>
            <div className="m-2"/>
            <div className="flex-col">
                <Spinner/>
            </div>

            <div className="m-2"/>
            <div className="m-2"/>
            <div className="flex-col">
                <Card/>
            </div>

            <div className="m-2"/>
            <div className="m-2"/>
            <div className="flex-col">
                <div className="flex-row items-center">
                    <Logo/> 
                    <div className="mr-4"/>
                    <div className="font-sans text-xl font-medium text-secondary">SwipeDeck</div>
                </div>
            </div>
        </div>
    )
}