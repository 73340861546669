export default function SendRequestButton({onClick, name, type, spinning}) {


    return (
        <button onClick={onClick} type={type === null ? "button": "submit"} className="m-2 transform hover:scale-110 w-min inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-gray-700 bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">
            <div className="flex flex-nowrap justify-around content-center text-white">
                <span>{name}</span>
                {spinning ?
                    <span className="ml-2">
                        <svg className={`animate-spin h-4 w-4 text-gray-700`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </span>
                : null}
            </div>
        </button>
    )
}