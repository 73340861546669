import { useState } from 'react'
import './FlipCard.css'
import {useDebounce} from 'use-debounce'


export default function FlipCard({front, back, flipRef, className}){

    // alter css when flip is called
    const [flipped, setFlipped] = useState(false)
    const [flippedDebounced] = useDebounce(flipped, 200)

    // flip card
    const flipCard = () => {
        setFlipped(!flipped)
        //console.log("Flipped to " + flipped)
    }

    flipRef.current = flipCard

    return(
        <div className="flip-card" >
            <div className="flip-card-inner" style={flipped ? {transform: "rotateY(180deg)"} : {transform: "rotateY(0deg)"}}>
                <div className="flip-card-front">
                    {flippedDebounced ? null : front}
                    {/* {front} */}
                </div>
                <div className="flip-card-back">
                    {flippedDebounced ? back : null}
                    {/* {back} */}
                </div>
            </div>
        </div>
    )
}