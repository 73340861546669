import { motion } from "framer-motion";
import React, { useState, useEffect } from 'react';

export default function BackgroudAnimation(){


    const [width, setWidth] = useState(window.innerWidth);
    const [height, ] = useState(window.innerHeight);


    useEffect(() => {
      function handleResize() {setWidth(window.innerWidth)   
      }
      window.addEventListener('resize', handleResize)
    })

    


    const ellipseVariants = {
        start: {
          d:
          "m-74.50166,288.34055c0,0 297.24702,-70.59702 494.68575,-203.14703c197.43872,-132.55002 615.58667,-15.85622 632.1261,56.06378c16.53943,71.92007 612.67236,1.5106 717.09448,-68.73434c104.42212,-70.2449 596.55676,-72.04547 593.61804,-72.4293c2.93872,0.38383 685.09131,-31.85682 682.1521,-31.473c2.93921,-0.38382 20.58032,59.49164 17.64111,59.10781c2.93921,0.38383 -304.89722,4.17715 -355.54517,14.26014c-50.64795,10.08298 -1559.9491,205.4706 -1638.2052,201.51744c-78.2561,-3.95316 -321.77545,-93.73781 -413.6983,-61.83569c-91.92285,31.90211 -229.74939,137.90164 -312.79926,70.12299c-83.04987,-67.77858 -458.23411,68.78781 -455.29379,68.40338", 
          scaleY: 1.0
        },
        mid: {
          d:
          "m-74.50166,288.34055c0,0 432.80006,-163.92953 688.01924,-154.25815c255.21674,9.6722 60.0321,66.3662 509.90332,47.17496c365.18811,-14.66142 541.56165,-38.48946 645.98376,-108.7344c104.42212,-70.2449 596.55676,-72.04547 593.61804,-72.4293c2.93872,0.38383 685.09131,-31.85682 682.1521,-31.473c2.93921,-0.38382 20.58032,59.49164 17.64111,59.10781c2.93921,0.38383 -304.89722,4.17715 -355.54517,14.26014c-50.64795,10.08298 -1648.83716,203.24832 -1722.64905,183.73961c-73.81207,-19.50868 -119.55347,30.70667 -240.3653,24.83121c-120.81152,-5.87546 -200.86047,-62.09868 -286.13257,-40.98833c-91.73761,25.10097 -573.78996,111.01006 -570.84964,110.62563",
        scalex: 1.2
        }
      };

    return(<div className="absolute top-1/8 left-0 overflow-hidden w-screen h-screen">
    
  
                    <motion.svg
                    initial="start"
                    width={(width+height) +200}
                    height={(width+height)}
                    viewBox={`500 -200 1500 1500`}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    animate="mid">
                        <motion.path
                            variants={ellipseVariants}
                            transition={{
                                duration: 10,
                                yoyo: Infinity,
                                repeat: Infinity
                                }}
                            fill="#C7481F"
                          
                            />
                    </motion.svg>
 
  
           
        </div>)

}