import React, { useState } from 'react';

export function Chip(props) {

    const [selected, setSelected] = useState(props.selected);

    const mapping = {'male': 'fas fa-mars',
    'female': 'fas fa-venus',
    'diverse': 'fas fa-transgender',
    'Not specified': 'fas fa-ban',
    '15-20 years': 'fas fa-birthday-cake',
    '21-25 years': 'fas fa-birthday-cake',
    '26-30 years': 'fas fa-birthday-cake',
    '31-35 years': 'fas fa-birthday-cake',
    '36-40 years': 'fas fa-birthday-cake',
    '41-45 years': 'fas fa-birthday-cake',
    '46-50 years': 'fas fa-birthday-cake',
    '51-55 years': 'fas fa-birthday-cake',
    '56-60 years': 'fas fa-birthday-cake',
    '61-65 years': 'fas fa-birthday-cake',
    '66-70 years': 'fas fa-birthday-cake',
    '70+ years': 'fas fa-birthday-cake',
    'No school-leaving qualification': 'fas fa-graduation-cap',
    'Completion of secondary school': 'fas fa-graduation-cap',
    'Vocational training': 'fas fa-graduation-cap',
    "Bachelor`s degree/professional qualification": 'fas fa-graduation-cap',
    "Bachelor's degree/professional qualification": 'fas fa-graduation-cap',
    "Master`s degree": 'fas fa-graduation-cap',
    "Master's degree": 'fas fa-graduation-cap',
    'Doctorate': 'fas fa-graduation-cap',
    'single': 'fas fa-heart',
    'married': 'fas fa-heart',
    'widowed': 'fas fa-heart',
    'divorced': 'fas fa-heart',
    '0': 'fas fa-baby',
    '1': 'fas fa-baby',
    '2': 'fas fa-baby',
    '3': 'fas fa-baby',
    '+4': 'fas fa-baby',
    'Employee': 'fas fa-suitcase',
    'Civil servant': 'fas fa-suitcase',
    'Self-employed': 'fas fa-suitcase',
    'Unemployed': 'fas fa-suitcase',
    'Retired': 'fas fa-suitcase',
    'Skilled worker': 'fas fa-sitemap',
    'Team leader': 'fas fa-sitemap',
    'Head of department': 'fas fa-sitemap',
    'Managing director': 'fas fa-sitemap',
    'Trade': 'fas fa-sitemap',
    'Health care': 'fas fa-user-nurse',
    'Automotive industry': 'fas fa-car',
    'Electrical industry': 'fas fa-bolt',
    'Energy industry': 'fas fa-bolt',
    'IT industry': 'fas fa-microchip',
    'Transport industry': 'fas fa-truck-moving',
    'Metal industry': 'fas fa-industry',
    'Real estate industry': 'fas fa-city',
    'Chemical industry': 'fas fa-industry',
    'Construction industry': 'fas fa-industry',
    'Service provider industry': 'fas fa-industry',
    'Public service': 'fas fa-industry',
    'Food industry': 'fas fa-hamburger',
    'Education': 'fas fa-book-open',
    'Insurance': 'fas fa-car-crash',
    'Plastics industry': 'fas fa-industry',
    'Glass & ceramics': 'fas fa-industry',
    'Textile industry': 'fas fa-tshirt',
    'Research &amp; Development': 'fas fa-vials',
    'Transportation': 'fas fa-truck-moving',
    'Steel Industry': 'fas fa-industry',
    'Management Consulting': 'fas fa-briefcase',
    'Pharmaceutical Industry': 'fas fa-industry',
    'Media Industry': 'fas fa-tv',
    'Telecommunications Industry': 'fas fa-phone',
    'Financial Services': 'fas fa-comment-dollar',
    'Printing Industry': 'fas fa-industry',
    'Social Services': 'fas fa-users',
    'Advertising Industry': 'fas fa-ad',
    'Auditing': 'fas fa-suitcase',
    'Banking': 'fas fa-suitcase',
    'Mining Industry': 'fas fa-industry',
    'Aviation': 'fas fa-plane',
    'Internet Industry': 'fas fa-globe',
    'Paper Industry': 'fas fa-industry',
    'Tourism Industry': 'fas fa-industry',
    'Leisure Industry': 'fas fa-industry',
    'Furniture Industry': 'fas fa-industry',
    'Hospitality Industry': 'fas fa-industry',
    'Timber Industry': 'fas fa-industry',
    '$': 'fas fa-hand-holding-usd',
    '$$': 'fas fa-hand-holding-usd',
    '$$$': 'fas fa-hand-holding-usd',
    '$$$$': 'fas fa-hand-holding-usd',
    'Cars': 'fas fa-grin-hearts',
    'Books &amp; Literature': 'fas fa-grin-hearts',
    'Business': 'fas fa-grin-hearts',
    'Career &amp; Occupation': 'fas fa-grin-hearts',
    'Events': 'fas fa-grin-hearts',
    'Family &amp; Parenting': 'fas fa-grin-hearts',
    'Food &amp; Drinking': 'fas fa-grin-hearts',
    'Video Games': 'fas fa-grin-hearts',
    'Health': 'fas fa-grin-hearts',
    'Home &amp; Garden': 'fas fa-grin-hearts',
    'Politics &amp; Law': 'fas fa-grin-hearts',
    'Film &amp; Watch': 'fas fa-grin-hearts',
    'Music &amp; Radio': 'fas fa-grin-hearts',
    'Personal Finance': 'fas fa-grin-hearts',
    'Pets': 'fas fa-grin-hearts',
    'Science': 'fas fa-grin-hearts',
    'Society': 'fas fa-grin-hearts',
    'Sports': 'fas fa-grin-hearts',
    'Style &amp; Fashion': 'fas fa-grin-hearts',
    'Technology &amp; Computing': 'fas fa-grin-hearts',
    'Travel': 'fas fa-grin-hearts',
    'Google': 'fab fa-google',
    'Facebook': 'fab fa-facebook',
    'Instagram': 'fab fa-instagram',
    'Twitter': 'fab fa-twitter',
    'Snapchat': 'fab fa-snapchat',
    'Pinterest': 'fab fa-pinterest',
    'Reddit': 'fab fa-reddit',
    'Youtube': 'fab fa-youtube',
    'Twitch': 'fab fa-twitch',
    'Xing': 'fab fa-xing',
    'LinkedIn': 'fab fa-linkedin',
    'TikTok': 'fab fa-tiktok',
    'TV': 'fas fa-tv',
    'Streaming': 'fas fa-cloud',
    'Social Networks': 'fas fa-project-diagram',
    'offline Newspapers &amp; Magazines': 'fas fa-newspaper',
    'online Newspapers &amp; Magazines': 'fas fa-newspaper',
    'Radio': 'fas fa-music',
    'conformity': 'fas fa-vial',
    'tradition': 'fas fa-vial',
    'charity': 'fas fa-vial',
    'universalism': 'fas fa-vial',
    'self-determined': 'fas fa-vial',
    'adventure': 'fas fa-vial',
    'hedonism': 'fas fa-vial',
    'achievement': 'fas fa-vial',
    'power': 'fas fa-vial',
    'security': 'fas fa-vial',
    'sociable': 'fas fa-vial',
    'introverted': 'fas fa-vial',
    'nosy': 'fas fa-vial',
    'conservative': 'fas fa-vial',
    'perfectionist': 'fas fa-vial',
    'chaotic': 'fas fa-vial',
    'considerate': 'fas fa-vial',
    'selfish': 'fas fa-vial',
    'sensitive': 'fas fa-vial',
    'insensitive': 'fas fa-vial'}

    var icon = props.icon;
    if(props.text in mapping){
        icon = mapping[props.text]
    }
    var text_ = props.text;
    text_ = text_.toString().replace("&amp;", "&")
    return (
        <button onClick={() => { try{props.onClick()}catch (e){}; props.isClickable ? setSelected(!selected): setSelected(selected)}} type={props.type === null ? "button": "submit"} className={`text-left font-sans text-2sm border ${selected?"font-medium border-primary text-primary bg-primaryUltraLight":"font-thin border-secondaryUltraLight text-secondary bg-white"} inline-flex items-center py-1 px-2 rounded-lg ${props.isClickable?"cursor-default":"cursor-not-allowed"} ${props.className ? props.className : ""}`}>
            <div className={`${icon} mr-1`}></div>
            <div>{text_}</div>
        </button>
    )
}