import { H1 } from '../../Components/BasicComponents/Text/H1';
import { Sub1 } from '../../Components/BasicComponents/Text/Sub1';
export default function ImpressumTextDE(){

    return(<div className="p-4 mt-4 lg:mt-0">
    <H1 text="Impressum"/>
    <div className="m-4"/>
        <Sub1 text="Informationen nach § 5 TMG:"/>
        <Sub1 text="Stellwerk3 GmbH"/>
        <div className="m-2"/>
        <Sub1 text="Adresse: Unter den Linden 4"/>
        <Sub1 text="Postleitzahl: 72762 Reutlingen"/>
        <Sub1 text="Tel.: 07121-99449-0"/>
        <Sub1 text="Fax: 07121-99449-69"/>
        <div className="m-2"/>
        <Sub1 text="E-Mail: jol@stellwerk3.de"/>
        <div className="m-2"/>
        <Sub1 text="Amtsgericht Stuttgart HRB 354052"/>
        <Sub1 text="UST-ID Nummer: DE220621358"/>
        <Sub1 text="Geschäftsführer: Armin Hipp"/>
    </div>
)

}