
export function H2(props) {
    var text = ""
    if (props.children){
        text = props.children
    }else{
        text = props.text
    }

    return (
        <div className= {`font-sans text-lg font-extrabold text-secondary ${props.position}`}>
            {text}
        </div>
    )
}