import { H1 } from '../../Components/BasicComponents/Text/H1';
import { Sub1 } from '../../Components/BasicComponents/Text/Sub1';
export default function ImpressumTextEN(){

    return(<div className="p-4 mt-4 lg:mt-0">
    <H1 text="Imprint"/>
    <div className="m-4"/>
    <Sub1 text="Information according to § 5 TMG:"/>
    <Sub1 text="Stellwerk3 GmbH"/>
    <div className="m-2"/>
    <Sub1 text="Adress: Unter den Linden 4"/>
    <Sub1 text="Zip: 72762 Reutlingen"/>
    <Sub1 text="Phone: 07121-99449-0"/>
    <Sub1 text="Fax: 07121-99449-69"/>
    <div className="m-2"/>
    <Sub1 text="Mail: jol@stellwerk3.de"/>
    <div className="m-2"/>
    <Sub1 text="Local Court Stuttgart HRB 354052"/>
    <Sub1 text="UST-ID number: DE220621358"/>
    <Sub1 text="Managing Director: Armin Hipp"/>

    
</div>
)

}