import React, { useEffect, useRef } from 'react';
import './swiper.css'

import { sendRequest, submitEvent } from '../../Components/Backend/Backend'
import FlipCard from './FlipCard/FlipCard';

import { PersonaCard } from '../../Components/BasicComponents/Cards/PersonaCard'
import {ButtonInputLgIcon} from '../../Components/BasicComponents/Buttons/ButtonInputLgIcon'



import { useSwipeable } from "react-swipeable";
import { MatchCard } from '../../Components/BasicComponents/Cards/MatchCard';
import { AudienceCard } from '../../Components/BasicComponents/Cards/AudienceCard';
import { HeaderNavigation } from '../../Components/UI/HeaderNavigation';
import Explainer from './Explainer/Explainer';
import { ProgressBar } from '../../Components/BasicComponents/ProgressBar';
import AdInfo from './AdInfo/AdInfo';


const personaQueue = [];
const queueLength = 1;


export default function Swiper(){

    const maxSwipes = 25;


    
    const [showAdInfo, setShowAdInfo] = React.useState(false);

    const [swipCounts, setSwipeCount] = React.useState(0);

    //const [isLoadingPersonas, setIsLoadingPersonas] = React.useState(false);
    const [, setIsLoadingPersonas] = React.useState(false);
    const [rawDataPersonas, setRawDataPersonas] = React.useState(null);

    const [isLoadingPersonaData, setIsLoadingPersonaData] = React.useState(false);
    const [rawDataPersonaData, setRawDataPersonaData] = React.useState(null);

    const [currentPersona, setCurrentPersona] = React.useState(null);
    const [currentPersonaData, setCurrentPersonaData] = React.useState(null);
    

    const [swiping, setSwiping] = React.useState(true);

    const [showResults, setShowResults] = React.useState(false);

    const [supressResults, setSupressResults] = React.useState(false);


    const getPersonas = async (previous_personas, n_personas) => {
        // https://audiencefinder-backend-p7inyw7pqq-ey.a.run.app/get_personas
        let data = {
            "method": "POST",
            "url": "https://audiencefinder-backend-p7inyw7pqq-ey.a.run.app/get_personas",
            "data": {
                "token": localStorage.getItem('token'),
                "shown_personas": previous_personas,
                "get_n_personas": n_personas
            }
        }

        sendRequest(data, setIsLoadingPersonas, setRawDataPersonas);
    }

    useEffect(() => {
        let likesData = localStorage.getItem('likes');
        let likes = []
        if(likesData != null){
            likes = JSON.parse(likesData);
        }
        getPersonas(likes, Math.max(queueLength - personaQueue.length, 1));

    }, [])



    useEffect(() => {
        if(rawDataPersonas !== null && rawDataPersonas.swipe_end !== undefined && rawDataPersonas.new_personas !== undefined){
            
            
            if(rawDataPersonas.swipe_end || rawDataPersonas.new_personas.length === 0){
                console.log("Swipe end:",rawDataPersonas.swipe_end )
                console.log("Supress Results:",supressResults)
                if (supressResults === false){
                    setSwiping(false);
                    setSupressResults(true);
                    cardFlipRef.current();
                    setCardAngle(0)
                }
                
            }
            if(personaQueue.length === 0){
                console.log("rawDataPersonas: Setting current persona to ", rawDataPersonas.new_personas[0]);
                setCurrentPersona(rawDataPersonas.new_personas.shift());
            }
            rawDataPersonas.new_personas.forEach(element => {
                personaQueue.push(element);
            });
            console.log("Persona queue: ", personaQueue);
        }
    }, [supressResults, rawDataPersonas])


    useEffect(() => {
        // if currentPersona is a valid persona ID, we request the backend for the persona data
        console.log("currentPersona: ", currentPersona)

        if(currentPersona !== null && typeof currentPersona === 'string'){
            // https://audiencefinder-backend-p7inyw7pqq-ey.a.run.app/get_personas_data
            let data = {
                "method": "POST",
                "url": "https://audiencefinder-backend-p7inyw7pqq-ey.a.run.app/get_personas_data",
                "data": {
                    "token": localStorage.getItem('token'),
                    "persona_ids": [currentPersona]
                }
            }
            sendRequest(data, setIsLoadingPersonaData, setRawDataPersonaData);
        }
    }, [currentPersona])


    useEffect(() => {
        console.log("Parsing persona data")
        // parse the raw personaData
        if(rawDataPersonaData !== null && rawDataPersonaData["personas"] && Array.isArray(rawDataPersonaData["personas"]) && rawDataPersonaData["personas"].length > 0){
            console.log(rawDataPersonaData["personas"][0]["frontend"])
            let data = JSON.parse(rawDataPersonaData["personas"][0]["frontend"].replace(/'/g, '"').replace('„', '"').replace('“', '"'))
            data["ID"] = rawDataPersonaData["personas"][0]["ids"]
            console.log("PArsed persona data: ", data)
            setCurrentPersonaData(data);
        }
    }, [rawDataPersonaData])

    const likeEvent = (likeEncoded) => {

        
        

        // increase Counter
        setSwipeCount((swipCounts+1)%maxSwipes);

        setCardAngle(0)
        setIsLoadingPersonaData(true);
        //console.log("Like event: ", likeEncoded);
        //console.log("personaQueue:", personaQueue)
        //console.log("Setting current persona to ", personaQueue[0]);
        setCurrentPersona(personaQueue.shift());

        // store the like in localstorage
        let likesData = localStorage.getItem('likes');
        let likes = []
        if(likesData != null){
            likes = JSON.parse(likesData);
        }

        if ((swipCounts > 13)){
            setSupressResults(false);
            console.log(likes.length)
        }

        if(currentPersona !== undefined){
            likes.push({id: currentPersona, like: likeEncoded});
            localStorage.setItem('likes', JSON.stringify(likes));

            getPersonas(likes, 1);
        }else{
            console.log("wrong swipe")
        }
        

    }

    const [cardAngle, setCardAngle] = React.useState(0);


    const maxSwipe = 200;

    const handlers = useSwipeable({
        delta: 10,
        trackMouse: true,
        onSwiped: (eventData) => {
            if(eventData.dir === "Left" && eventData.deltaX < -maxSwipe && !isLoadingPersonaData){
                likeEvent(0);
                submitEvent("swiping","nope")
            }else if(eventData.dir === "Right" && eventData.deltaX > maxSwipe && !isLoadingPersonaData){
                likeEvent(1);
                submitEvent("swiping","like")
            }else{
                setCardAngle(0)
            }
        },
        onSwiping: (eventData) => {
            
            if((eventData.deltaX < maxSwipe || eventData.deltaX > -maxSwipe) && !eventData.first){
                setCardAngle(eventData.deltaX / maxSwipe)
            }
        }
    });


    //const [isLoadingResult, setIsLoadingResult] = React.useState(false);
    const [, setIsLoadingResult] = React.useState(false);
    const [rawDataResult, setRawDataResult] = React.useState(null);

    useEffect(() => {
        if(!swiping){
            let data = {
                "method": "POST",
                "url": "https://audiencefinder-backend-p7inyw7pqq-ey.a.run.app/identify_target_group",
                //"url": "http://192.168.178.61:8080/identify_target_group",
                "data": {
                    "token": localStorage.getItem('token'),
                    "persona_ids": JSON.parse(localStorage.getItem('likes'))
                }
            }
            sendRequest(data, setIsLoadingResult, setRawDataResult);
        }
    }, [swiping])

    
    const cardFlipRef = useRef(null);

    function animateDislike() {
            setCardAngle(-60)
            setTimeout(()=>{
                setCardAngle(0)
               }, 500)
        
    }

    function animateLike() {
        setCardAngle(60)
        setTimeout(()=>{
            setCardAngle(0)
           }, 500)
    
}

    function restart() {
        setSwipeCount(0)
        localStorage.removeItem('likes');
        setSwiping(true);
        getPersonas([], 1);
        setShowResults(false);
        cardFlipRef.current();
        setCardAngle(0)
    }


    function continueSwiping() {
        setSwipeCount(0)
        setSwiping(true);
        getPersonas([], 1);
        setShowResults(false);
        setSupressResults(true);
        cardFlipRef.current();
        setIsLoadingPersonaData(false)
        setCardAngle(0)

       
    }



    return(
        <div className="h-full w-full bg-base overflow-y-visible">
            <HeaderNavigation />
            <ProgressBar progress={swipCounts/maxSwipes}/>
            <div className="relative flex justify-center h-full w-full">



            <Explainer/>

            { !showResults ?
                <FlipCard front={
                        <div {...handlers} className="flex flex-col flex-wrap justify-center items-center overflow-hidden">
                            {
                                currentPersonaData ? 
                                    <PersonaCard angle={cardAngle} name={currentPersonaData.Name} age={currentPersonaData.Age} job={currentPersonaData.Job} income={currentPersonaData.Income} id={currentPersonaData.ID}
                                        content={
                                            currentPersonaData
                                        } loading={isLoadingPersonaData} />
                                :
                                <div>
                                    <PersonaCard angle={0} name={"Max Mustermann"} age={"29"} job={"Data Analyst"} income={"$$"} id={"47a34253"}
                                        content={{"Age Group": ["26-30 years"],
                                                  "Personality": ["introverted", "considerate", "sensitive"],
                                                  "Interest": ["Events", "Food &amp; Drinking"]
                                                  }} loading={true} />
                                </div>    
                            }
                            <div className="flex flex-row justify-center space-x-8 mt-8 mb-2">
                                <ButtonInputLgIcon isDisabled={isLoadingPersonaData} onClick={() => {likeEvent(0);animateDislike();submitEvent("swiping","nope")}} text ="Nope" icon="fas fa-times" color = "secondary" colorHover ="secondaryLight" />
                                <ButtonInputLgIcon isDisabled={isLoadingPersonaData} onClick={() => {likeEvent(1);animateLike();submitEvent("swiping","like")}} text ="Like" icon="fas fa-heart"color = "secondary" colorHover ="secondaryLight" />
                            </div>
                        </div>
                } back={
                    <div className="flex flex-col flex-wrap justify-center items-center">
                        <MatchCard continueSwiping={()=> {continueSwiping();submitEvent("match_found","continue_swiping")} } showMatches={() => {setShowResults(true);submitEvent("match_found","show_results")}} />
                    </div>
                } flipRef={cardFlipRef} />
            :
                <div className="mt-10">
                    <div className="flex flex-col gap-y-8 justify-center">
                        <div className="flex justify-center gap-4 flex-wrap">
                        {
                            rawDataResult && Array.isArray(rawDataResult) ? 
                                rawDataResult.map((element, index) => {
                                    return (
                                        // <Analysis responseData={element} />
                                        <AudienceCard number={index} content={element} setShowAdInfo = {setShowAdInfo} />
                                    )
                                })
                                : null
                        }
                        </div>
                        <div className="flex p-2 flex-col lg:flex-row justify-center gap-2 mb-4 lg:space-x-8 lg:mt-8 lg:mb-2">
                            <ButtonInputLgIcon icon="fa fa-comment" color="secondary" text="Give Feedback" onClick={()=>{document.location.href='/feedback';submitEvent("audience_display","give_feedback")}} />
                            <ButtonInputLgIcon icon="fa fa-redo" color="secondary" text="Reset swipes" onClick={()=>{restart();submitEvent("audience_display","reset_swiping") }} />
                            <ButtonInputLgIcon icon="fa fa-wrench" color="primary" text="Refine audience" onClick={()=> {continueSwiping();submitEvent("audience_display","refine_audience")} } />
                        </div>
                    </div>
                    {showAdInfo ? 
                    <AdInfo setShowAdInfo={setShowAdInfo}/>:
                    ""
                    }
                    
                </div>
            }

            
            </div>
        </div>
    )
}