import { HeaderNavigation } from '../../Components/UI/HeaderNavigation';
import React, { useState } from 'react';
import { Footer } from '../../Components/UI/Footer';
import TermsEN from './TermsEN';
import TermsDE from './TermsDE';


export default function Terms(){

    //const [langEn, setLangEn] = useState(true)
    const [langEn, ] = useState(true)
    return(
        
        <div>
                
                <HeaderNavigation className="z-50"/>


                <div className="z-0 flex justify-center w-full">

            
                    <div class="p-1 lg:p-8 bg-white mt-8 lg:mt-32 w-full lg:w-1/2 m-2 lg:m-0 lg:w-1/2 h-2/3 grid grid-cols-1 gap-2 divide-secondaryUltraLight rounded-lg overflow-hidden shadow-lg border-secondary border-b-4 border-2">
                        <div class="relative  w-full">
                            <div class="absolute top-1 lg:top-0 right-8 lg:right-4  h-16 w-16">
                                   </div>
                        </div>
                        {langEn ? 
                        <TermsEN/>
                        :
                        <TermsDE/>
                        }
                    </div>    
            
                </div>
        <Footer/>      
        </div>
    )
}