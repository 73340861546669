
export function ButtonInputLgIcon(props) {

    return (
        <button onClick={() => props.isDisabled ? null : props.onClick() } type={props.type === null ? "button": "submit"} className={`flex justify-center font-sans text-lg font-thin transform hover:bg-${props.colorHover} inline-flex items-center py-3 px-5 border border-transparent text-xs font-medium rounded-lg shadow-sm text-white bg-${props.color} ${props.isDisabled?"cursor-default":""} focus:ring-${props.color} ${props.isDisabled?"cursor-not-allowed":`focus:outline-none focus:ring-2 focus:ring-offset-2`}`}>
            <div className="flex flex-row justify-center items-center ">
                <div className={`${props.icon} mr-4`}></div>
                <div>{props.text}</div>
            </div>
           
        </button>
    )
}