import { H1 } from '../../Components/BasicComponents/Text/H1';
import { H2 } from '../../Components/BasicComponents/Text/H2';
import { H3 } from '../../Components/BasicComponents/Text/H3';
import { Sub2 } from '../../Components/BasicComponents/Text/Sub2';

export default function DatenschutzTextEN(){

    return(<div className="p-4 mt-4 lg:mt-0">
    <H1 text="Privacy policy"/>
        <Sub2 text="The responsible party within the meaning of the data protection laws, in particular the EU General Data Protection Regulation (DSGVO), is:"/>
        <div className="m-1"/>
        <Sub2 text="Stellwerk3 GmbH"/>
        <div className="m-4"/>
        <H2 text="Your data subject rights"/>
        <div>
            <p className="font-sans text-3sm font-thin">You can exercise the following rights at any time using the contact details provided for our data protection officer:</p>
            <ul class="list-disc font-sans text-3sm font-thin ml-8">
                <li>Information about your data stored by us and its processing (Art. 15 DSGVO),</li>
                <li>Correction of incorrect personal data (Art. 16 DSGVO),</li>
                <li>Deletion of your data stored by us (Art. 17 DSGVO),</li>
                <li>Restriction of data processing if we are not yet allowed to delete your data due to legal obligations (Art. 18 DSGVO),</li>
                <li>Objection to the processing of your data by us (Art. 21 DSGVO) and</li>   
                <li>Data portability, insofar as you have consented to the data processing or have concluded a contract with us (Art. 20 DSGVO).</li>            
            </ul>
            <p className="font-sans text-3sm font-thin">If you have given us consent, you can revoke this at any time with effect for the future.
You can lodge a complaint with a supervisory authority at any time, e.g. the competent supervisory authority in the federal state of your residence or the authority responsible for us as the controller.
A list of the supervisory authorities (for the non-public sector) with address can be found at: <a className="underline" href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html">https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html</a>.
            </p>
        </div>

        <div className="m-4"/>
        <H2 text="Collection of general information when visiting our website"/>
        <H3 text="Nature and purpose of processing:"/>
        <div>
            <p className="font-sans text-3sm font-thin">When you access our website, i.e. if you do not register or otherwise transmit information, information of a general nature is automatically collected. This information (server log files) includes, for example, the type of web browser, the operating system used, the domain name of your internet service provider, your IP address and the like. 
In particular, they are processed for the following purposes:</p>
            <ul class="list-disc font-sans text-3sm font-thin ml-8">
                <li>Ensuring a problem-free connection of the website,</li>
                <li>Ensuring a smooth use of our website,</li>
                <li>Evaluation of system security and stability, and</li>
                <li>to optimise our website.</li>
            </ul>
            <p className="font-sans text-3sm font-thin">We do not use your data to draw conclusions about your person. Information of this kind is evaluated anonymously by us statistically, if necessary, in order to optimise our website and the technology behind it.</p>
        </div>

        <div className="m-2"/>
        <H3 text="Legal basis and legitimate interest:"/>
        <div>
            <p className="font-sans text-3sm font-thin">The processing is carried out in accordance with Art. 6 para. 1 lit. f DSGVO on the basis of our legitimate interest in improving the stability and functionality of our website.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Recipient:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Recipients of the data may be technical service providers who act as order processors for the operation and maintenance of our website.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Storage time:"/>
        <div>
            <p className="font-sans text-3sm font-thin">The data is deleted as soon as it is no longer required for the purpose for which it was collected. For data used to provide the website, this is generally the case when the respective session has ended. 
In the case of storage of data in log files, this is the case after 14 days at the latest. Storage beyond this period is possible. In this case, the IP addresses of the users are anonymised so that it is no longer possible to assign the calling client.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Provision prescribed or required:"/>
        <div>
            <p className="font-sans text-3sm font-thin">The provision of the aforementioned personal data is neither legally nor contractually required. However, without the IP address, the service and functionality of our website cannot be guaranteed. In addition, individual services and services may not be available or may be restricted. For this reason, an objection is excluded.</p>
        </div>

        <div className="m-3"/>
        <H2 text="Newsletter"/>
        <H3 text="Nature and purpose of the processing:"/>
        <div>
            <p className="font-sans text-3sm font-thin">For the delivery of our newsletter, we collect personal data that is transmitted to us via an input mask.
For an effective registration, we require a valid e-mail address. In order to verify that a registration is actually made by the owner of an e-mail address, we use the "double opt-in" procedure. For this purpose, we log the registration for the newsletter, the sending of a confirmation email and the receipt of the response requested herewith. No further data is collected. </p>
        </div>
        <div className="m-2"/>
        <H3 text="Legal basis:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Based on your express consent (Art. 6 para. 1 lit. a DSGVO), we will regularly send you our newsletter or comparable information by e-mail to your specified e-mail address. 
You can revoke your consent to the storage of your personal data and its use for sending the newsletter at any time with effect for the future. You will find a link to this effect in every newsletter. You can also unsubscribe directly on this website at any time or inform us of your revocation using the contact option provided at the end of this privacy policy.
</p>
        </div>
        <div className="m-2"/>
        <H3 text="Recipient:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Recipients of the data are, if applicable, order processors.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Storage time:"/>
        <div>
            <p className="font-sans text-3sm font-thin">In this context, the data will only be processed as long as the corresponding consent has been given. Afterwards they will be deleted.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Provision prescribed or required:"/>
        <div>
            <p className="font-sans text-3sm font-thin">The provision of your personal data is voluntary, based solely on your consent. Unfortunately, we cannot send you our newsletter without your consent.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Withdrawal of consent:"/>
        <div>
            <p className="font-sans text-3sm font-thin">You can withdraw your consent to the storage of your personal data and its use for the newsletter mailing at any time with effect for the future. Unsubscribing can be requested via the link contained in every email or from the data protection officer or person responsible for data protection listed below.</p>
        </div>
        

        <div className="m-3"/>
        <H2 text="Contact form"/>
        <H3 text="Nature and purpose of the processing:"/>
        <div>
            <p className="font-sans text-3sm font-thin">The data you enter will be stored for the purpose of individual communication with you. For this purpose, it is necessary to provide a valid e-mail address and your name. This is used for the assignment of the enquiry and the subsequent response to it. The provision of further data is optional.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Legal basis:"/>
        <div>
            <p className="font-sans text-3sm font-thin">The processing of the data entered in the contact form is based on a legitimate interest (Art. 6 para. 1 lit. f DSGVO).
By providing the contact form, we would like to enable you to contact us in an uncomplicated manner. The information you provide will be stored for the purpose of processing the enquiry and for possible follow-up questions.
If you contact us to request a quote, the data entered in the contact form will be processed to carry out pre-contractual measures (Art. 6 para. 1 lit. b DSGVO).</p>
        </div>
        <div className="m-2"/>
        <H3 text="Recipient:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Recipients of the data are, if applicable, order processors.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Storage time:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Data will be deleted no later than 6 months after the request has been processed.
If a contractual relationship is established, we are subject to the statutory retention periods according to the German Commercial Code (HGB) and delete your data after these periods have expired.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Provision prescribed or required:"/>
        <div>
            <p className="font-sans text-3sm font-thin">The provision of your personal data is voluntary. However, we can only process your request if you provide us with your name, e-mail address and the reason for the request.</p>
        </div>
       
        <div className="m-3"/>
        <H2 text="Use of script libraries (Google Web Fonts)"/>
        <div>
            <p className="font-sans text-3sm font-thin">In order to display our content correctly and in a graphically appealing manner across browsers, we use "Google Web Fonts" from Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; hereinafter "Google") on this website to display fonts.
You can find more information about Google Web Fonts at <a className="underline" href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> and in Google's privacy policy: <a className="underline" href="https://www.google.com/policies/privacy/.">https://www.google.com/policies/privacy/.</a></p>
        </div>
        <div className="m-3"/>
        <H2 text="SSL encryption"/>
        <div>
            <p className="font-sans text-3sm font-thin">To protect the security of your data during transmission, we use state-of-the-art encryption procedures (e.g. SSL) via HTTPS.</p>
        </div>

        <div className="m-5"/>
        <H2 text="Information about your right to object according to Art. 21 DSGVO"/>
        <H3 text="Right to object on a case-by-case basis"/>
        <div>
            <p className="font-sans text-3sm font-thin">You have the right to object at any time, on grounds relating to your particular situation, to the processing of personal data relating to you which is carried out on the basis of Art. 6(1)(f) DSGVO (data processing on the basis of a balance of interests); this also applies to profiling based on this provision within the meaning of Art. 4 No. 4 DSGVO.
If you object, we will no longer process your personal data unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing serves to assert, exercise or defend legal claims.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Recipient of an opposition"/>
        <div>
            <p className="font-sans text-3sm font-thin">Stellwerk3 GmbH:
            <ul class="list-disc font-sans text-3sm font-thin ml-8">
                <li>Adress: Unter den Linden 4 </li>
                <li>Zip-Code: 72762 Reutlingen </li>
                <li>Country: Germany</li>
                <li>Phone: +49-7121 – 994 490</li>
                <li>Mail: info@stellwerk3.de</li>
                <li>Website: <a className="underline" href="https://stellwerk3.de/">https://stellwerk3.de/</a></li>
            </ul>
            </p>
        </div>
        <div className="m-5"/>
        <H2 text="Change to our privacy policy"/>
        <div>
            <p className="font-sans text-3sm font-thin">We reserve the right to adapt this data protection declaration so that it always complies with the current legal requirements or in order to implement changes to our services in the data protection declaration, e.g. when introducing new services. The new data protection statement will then apply to your next visit.</p>
        </div>
        <div className="m-5"/>
        <H2 text="Questions to the Data Protection Officer"/>
        <div>
            <p className="font-sans text-3sm font-thin">If you have any questions about data protection, please write us an e-mail or contact the person responsible for data protection in our organisation directly: Dipl.-Ing.(BA) Matthias Walliser</p>
            <ul class="list-disc font-sans text-3sm font-thin ml-8">
                <li>Datenschutzberatung Walliser</li>
                <li>Adress: Altenwaldstraße 8</li>
                <li>Zip-Code: 72768 Reutlingen</li>
                <li>Country: Germany</li>
                <li>Phone: 07121 630 321</li>
                <li>Mail: kontakt@walliser-datenschutz.de</li>
                <li>Website: <a className="underline" href="http://www.walliser-datenschutz.de">http://www.walliser-datenschutz.de</a></li>
            </ul>
        </div>
        <div className="m-5"/>
        <p className="font-sans text-3sm font-thin">The privacy policy was created with the help of activeMind AG, the experts for external data protection officers (version #2020-09-30).
</p>
        
    </div>
)

}