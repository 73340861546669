import React, { useState } from 'react';
import { ReactComponent as Logo } from '../../logo.svg';

export function HeaderNavigation(props) {

    const [showSideBar, setShowSideBar] = useState(true);

    //var isUserAuth = false;
    //if(localStorage.getItem("token")){
    //    isUserAuth = true;
    //}

    return(
       <div className="z-50 flex flex-col shadow-lg">
            <div className="relative bg-white">
                <div className="flex justify-between items-center px-2 py-2 sm:px-6 md:justify-start md:space-x-10">
                    <div onClick={()=> {document.location.href='/'}} className="cursor-pointer flex justify-start lg:w-0 lg:flex-1 ml-1">
                        <div className="flex flex-row items-center">
                            <Logo/> 
                        <div className="mr-4"/>
                        <div className="font-sans text-xl font-medium text-secondary cursor-pointers">AudienceDeck</div>
                    </div>
                    </div>
                    <div className="-mr-2 -my-2 md:hidden">
                    <button type="button" onClick={() => {setShowSideBar(!showSideBar)}} className="mr-2 bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary" aria-expanded="false">
                        <span className="sr-only">Open menu</span>
                        
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                    </div>


                  
                    <div className={`${showSideBar ? "visible transition transform translate-x-full duration-500": "transition transform translate-x-0 duration-500"} z-50 fixed inset-0 overflow-hidden`} aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
                    <div className="absolute inset-0 overflow-hidden">
                        
                        <div className="absolute inset-0 z-50" aria-hidden="true">
                        <div className="fixed inset-y-0 right-0 pl-20 max-w-full flex">
                           
                            <div className="w-screen max-w-md">
                            <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                                <div className="px-4 sm:px-6">
                                <div className="flex items-start justify-between">
                                    <h2 className="text-lg font-medium text-gray-900" id="slide-over-title">
                                    Menu
                                    </h2>
                                    <div className="ml-3 h-7 flex items-center">
                                    <button type="button" onClick={() => {setShowSideBar(!showSideBar)}} className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                                        <span className="sr-only">Close panel</span>
                                       
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                    </div>
                                </div>
                                </div>
                                <div className="mt-6 relative flex-1 px-4 sm:px-6">
                               
                                <div className="absolute inset-0 px-4 sm:px-6">
                                    <div className="h-full flex flex-col justify-top" aria-hidden="true">
                                        <a href="/feedback" className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-light text-secondaryLight bg-white">
                                            Feedback
                                        </a>
                                        
                                      

                                        <a href="/login" className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-light text-secondaryLight bg-white">
                                            Login
                                        </a>

                                        <a href="/app" className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-md text-white bg-primary hover:bg-primaryLight">
                                            Find audience
                                        </a>
                                    </div>
                                </div>
                               
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>



                    <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                
                        
                        <a href="/feedback" className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-light text-secondaryLight bg-white">
                            Feedback
                        </a>
                        <a href="/login" className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-light text-secondaryLight bg-white">
                            Login
                        </a>
                        <a href="/app" className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-md text-white bg-primary hover:bg-primaryLight">
                            Find audience
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )
}