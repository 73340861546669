import { H1 } from '../../Components/BasicComponents/Text/H1';
import { H2 } from '../../Components/BasicComponents/Text/H2';
import { H3 } from '../../Components/BasicComponents/Text/H3';
import { Sub2 } from '../../Components/BasicComponents/Text/Sub2';
export default function DatenschutzTextDE(){

    return(<div className="p-4 mt-4 lg:mt-0">
    <H1 text="Datenschutzerklärung"/>
        <Sub2 text="Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:"/>
        <div className="m-1"/>
        <Sub2 text="Stellwerk3 GmbH"/>
        <div className="m-4"/>
        <H2 text="Ihre Betroffenenrechte"/>
        <div>
            <p className="font-sans text-3sm font-thin">Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:</p>
            <ul class="list-disc font-sans text-3sm font-thin ml-8">
                <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
                <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
                <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
                <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),</li>
                <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>   
                <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>            
            </ul>
            <p className="font-sans text-3sm font-thin">Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.
            Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige Behörde.
            Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: <a className="underline" href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html">https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html</a>.
            </p>
        </div>

        <div className="m-4"/>
        <H2 text="Erfassung allgemeiner Informationen beim Besuch unserer Website"/>
        <H3 text="Art und Zweck der Verarbeitung:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig Informationen übermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse und ähnliches. 
Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
            <ul class="list-disc font-sans text-3sm font-thin ml-8">
                <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
                <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
                <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                <li>zur Optimierung unserer Website.</li>
            </ul>
            <p className="font-sans text-3sm font-thin">Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art werden von uns ggfs. anonymisiert statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren.</p>
        </div>

        <div className="m-2"/>
        <H3 text="Rechtsgrundlage und berechtigtes Interesse:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilität und Funktionalität unserer Website.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Empfänger:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig werden.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Speicherdauer:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist für die Daten, die der Bereitstellung der Website dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung beendet ist. 
Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens 14 Tagen der Fall. Eine darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer anonymisiert, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Bereitstellung vorgeschrieben oder erforderlich:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein Widerspruch ausgeschlossen. </p>
        </div>

        <div className="m-3"/>
        <H2 text="Newsletter"/>
        <H3 text="Art und Zweck der Verarbeitung:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Für die Zustellung unseres Newsletters erheben wir personenbezogene Daten, die über eine Eingabemaske an uns übermittelt werden.
Für eine wirksame Registrierung benötigen wir eine valide E-Mail-Adresse. Um zu überprüfen, dass eine Anmeldung tatsächlich durch den Inhaber einer E-Mail-Adresse erfolgt, setzen wir das „Double-Opt-in“-Verfahren ein. Hierzu protokollieren wir die Anmeldung zum Newsletter, den Versand einer Bestätigungsmail und den Eingang der hiermit angeforderten Antwort. Weitere Daten werden nicht erhoben. 
</p>
        </div>
        <div className="m-2"/>
        <H3 text="Rechtsgrundlage:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Auf Grundlage Ihrer ausdrücklich erteilten Einwilligung (Art. 6 Abs. 1 lit. a DSGVO), übersenden wir Ihnen regelmäßig unseren Newsletter bzw. vergleichbare Informationen per E-Mail an Ihre angegebene E-Mail-Adresse. 
Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer Nutzung für den Newsletterversand können Sie jederzeit mit Wirkung für die Zukunft widerrufen. In jedem Newsletter findet sich dazu ein entsprechender Link. Außerdem können Sie sich jederzeit auch direkt auf dieser Website abmelden oder uns Ihren Widerruf über die am Ende dieser Datenschutzhinweise angegebene Kontaktmöglichkeit mitteilen.
</p>
        </div>
        <div className="m-2"/>
        <H3 text="Empfänger:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Speicherdauer:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Die Daten werden in diesem Zusammenhang nur verarbeitet, solange die entsprechende Einwilligung vorliegt. Danach werden sie gelöscht.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Bereitstellung vorgeschrieben oder erforderlich:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Ohne bestehende Einwilligung können wir Ihnen unseren Newsletter leider nicht zusenden.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Widerruf der Einwilligung:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer Nutzung für den Newsletterversand können Sie jederzeit mit Wirkung für die Zukunft widerrufen. Die Abmeldung kann über den in jeder E-Mail enthaltenen Link oder beim unten aufgeführten Datenschutzbeauftragten bzw. der für den Datenschutz zuständigen Person beantragt werden.</p>
        </div>
        

        <div className="m-3"/>
        <H2 text="Kontaktformular"/>
        <H3 text="Art und Zweck der Verarbeitung:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Rechtsgrundlage:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).
Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.
Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO).
</p>
        </div>
        <div className="m-2"/>
        <H3 text="Empfänger:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Speicherdauer:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.
Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach Ablauf dieser Fristen.</p>
        </div>
        <div className="m-2"/>
        <H3 text="Bereitstellung vorgeschrieben oder erforderlich:"/>
        <div>
            <p className="font-sans text-3sm font-thin">Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.</p>
        </div>
       
        <div className="m-3"/>
        <H2 text="Verwendung von Scriptbibliotheken (Google Webfonts)"/>
        <div>
            <p className="font-sans text-3sm font-thin">Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; nachfolgend „Google“) zur Darstellung von Schriften.
Weitere Informationen zu Google Web Fonts finden Sie unter <a className="underline" href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> und in der Datenschutzerklärung von Google: <a className="underline" href="https://www.google.com/policies/privacy/.">https://www.google.com/policies/privacy/.</a></p>
        </div>
        <div className="m-3"/>
        <H2 text="SSL-Verschlüsselung"/>
        <div>
            <p className="font-sans text-3sm font-thin">Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>
        </div>

        <div className="m-5"/>
        <H2 text="Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO"/>
        <H3 text="Einzelfallbezogenes Widerspruchsrecht"/>
        <div>
            <p className="font-sans text-3sm font-thin">Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.
Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
</p>
        </div>
        <div className="m-2"/>
        <H3 text="Empfänger eines Widerspruchs"/>
        <div>
            <p className="font-sans text-3sm font-thin">Stellwerk3 GmbH:
            <ul class="list-disc font-sans text-3sm font-thin ml-8">
                <li>Adresse: Unter den Linden 4 </li>
                <li>Postleitzahl: 72762 Reutlingen </li>
                <li>Land: Deutschland</li>
                <li>Tel.: +49-7121 – 994 490</li>
                <li>E-Mail: info@stellwerk3.de</li>
                <li>Webseite: <a className="underline" href="https://stellwerk3.de/">https://stellwerk3.de/</a></li>
            </ul>
            </p>
        </div>
        <div className="m-5"/>
        <H2 text="Änderung unserer Datenschutzbestimmungen"/>
        <div>
            <p className="font-sans text-3sm font-thin">Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>
        </div>
        <div className="m-5"/>
        <H2 text="Fragen an den Datenschutzbeauftragten"/>
        <div>
            <p className="font-sans text-3sm font-thin">Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation: Dipl.-Ing.(BA) Matthias Walliser</p>
            <ul class="list-disc font-sans text-3sm font-thin ml-8">
                <li>Datenschutzberatung Walliser</li>
                <li>Adresse: Altenwaldstraße 8</li>
                <li>Postalcode: 72768 Reutlingen</li>
                <li>Land: Deutschland</li>
                <li>Tel.: 07121 630 321</li>
                <li>E-Mail: kontakt@walliser-datenschutz.de</li>
                <li>Webseite: <a className="underline" href="http://www.walliser-datenschutz.de">http://www.walliser-datenschutz.de</a></li>
            </ul>
        </div>
        <div className="m-5"/>
        <p className="font-sans text-3sm font-thin">Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für externe Datenschutzbeauftragte (Version #2020-09-30).
</p>
        
    </div>
)

}