


export function Spinner(props) {

    return (
        <div className={props.className}>
            <div style={{"borderTopColor":"transparent"}}
                className="w-20 h-20 border-4 border-primary border-solid  rounded-full animate-spin"></div>
        </div>
    )
}