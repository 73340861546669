import { H2 } from "../Text/H2";
import { Sub2 } from "../Text/Sub2";
import { Chip } from "../Chips/Chip";
import { H1 } from "../Text/H1";
import React from "react";

import FlipCard from "../../../Sites/Swiper/FlipCard/FlipCard";
import { ButtonInputSm } from "../Buttons/ButtonInputSm";
import { Sub1 } from "../Text/Sub1";
import { H3 } from "../Text/H3";
import { ButtonInputMdIcon } from "../Buttons/ButtonInputMdIcon";
import { Bar } from 'react-chartjs-2';
import { submitEvent } from "../../Backend/Backend";



const mapping_category = {'male': 'demographics',
'female': 'demographics',
'diverse': 'demographics',
'Not specified': 'demographics',
'15-20 years': 'demographics',
'21-25 years': 'demographics',
'26-30 years': 'demographics',
'31-35 years': 'demographics',
'36-40 years': 'demographics',
'41-45 years': 'demographics',
'46-50 years': 'demographics',
'51-55 years': 'demographics',
'56-60 years': 'demographics',
'61-65 years': 'demographics',
'66-70 years': 'demographics',
'70+ years': 'demographics',
'No school-leaving qualification': 'education',
'Completion of secondary school': 'education',
'Vocational training': 'education',
"Bachelor`s degree/professional qualification": 'education',
"Bachelor's degree/professional qualification": 'education',
"Master`s degree": 'education',
"Master's degree": 'education',
'Doctorate': 'education',
'single': 'family',
'married': 'family',
'widowed': 'family',
'divorced': 'family',
'0': 'family',
'1': 'family',
'2': 'family',
'3': 'family',
'+4': 'family',
'Employee': 'workstate',
'Civil servant': 'workstate',
'Self-employed': 'workstate',
'Unemployed': 'workstate',
'Retired': 'workstate',
'Skilled worker': 'hierarchy',
'Team leader': 'hierarchy',
'Head of department': 'hierarchy',
'Managing director': 'hierarchy',
'Trade': 'hierarchy',
'Health care': 'branch',
'Automotive industry': 'branch',
'Electrical industry': 'branch',
'Energy industry': 'branch',
'IT industry': 'branch',
'Transport industry': 'branch',
'Metal industry': 'branch',
'Real estate industry': 'branch',
'Chemical industry': 'branch',
'Construction industry': 'branch',
'Service provider industry': 'branch',
'Public service': 'branch',
'Food industry': 'branch',
'Education': 'branch',
'Insurance': 'branch',
'Plastics industry': 'branch',
'Glass & ceramics': 'branch',
'Textile industry': 'branch',
'Research &amp; Development': 'branch',
'Transportation': 'branch',
'Steel Industry': 'branch',
'Management Consulting': 'branch',
'Pharmaceutical Industry': 'branch',
'Media Industry': 'branch',
'Telecommunications Industry': 'branch',
'Financial Services': 'branch',
'Printing Industry': 'branch',
'Social Services': 'branch',
'Advertising Industry': 'branch',
'Auditing': 'branch',
'Banking': 'branch',
'Mining Industry': 'branch',
'Aviation': 'branch',
'Internet Industry': 'branch',
'Paper Industry': 'branch',
'Tourism Industry': 'branch',
'Leisure Industry': 'branch',
'Furniture Industry': 'branch',
'Hospitality Industry': 'branch',
'Timber Industry': 'branch',
'$': 'income',
'$$': 'income',
'$$$': 'income',
'$$$$': 'income',
'Cars': 'intrest',
'Books &amp; Literature': 'intrest',
'Business': 'intrest',
'Career &amp; Occupation': 'intrest',
'Events': 'intrest',
'Family &amp; Parenting': 'intrest',
'Food &amp; Drinking': 'intrest',
'Video Games': 'intrest',
'Health': 'intrest',
'Home &amp; Garden': 'intrest',
'Politics &amp; Law': 'intrest',
'Film &amp; Watch': 'intrest',
'Music &amp; Radio': 'intrest',
'Personal Finance': 'intrest',
'Pets': 'intrest',
'Science': 'intrest',
'Society': 'intrest',
'Sports': 'intrest',
'Style &amp; Fashion': 'intrest',
'Technology &amp; Computing': 'intrest',
'Travel': 'intrest',
'Google': 'plattform',
'Facebook': 'plattform',
'Instagram': 'plattform',
'Twitter': 'plattform',
'Snapchat': 'plattform',
'Pinterest': 'plattform',
'Reddit': 'plattform',
'Youtube': 'plattform',
'Twitch': 'plattform',
'Xing': 'plattform',
'LinkedIn': 'plattform',
'TikTok': 'plattform',
'TV': 'media',
'Streaming': 'media',
'Social Networks': 'media',
'offline Newspapers &amp; Magazines': 'media',
'online Newspapers &amp; Magazines': 'media',
'Radio': 'media',
'conformity': 'values',
'tradition': 'values',
'charity': 'values',
'universalism': 'values',
'self-determined': 'values',
'adventure': 'values',
'hedonism': 'values',
'achievement': 'values',
'power': 'values',
'security': 'values',
'sociable': 'personality',
'introverted': 'personality',
'nosy': 'personality',
'conservative': 'personality',
'perfectionist': 'personality',
'chaotic': 'personality',
'considerate': 'personality',
'selfish': 'personality',
'sensitive': 'personality',
'insensitive': 'personality'}



const filter = function (name){
    const category = mapping_category[name];
    const filter_categories = ["workstate", "hierarchy","branch","media"]
    
    if (filter_categories.includes(category)){
        return false;
    }else{
        return true;
    }
}

const typeToComponent = (unknownObject) => {
    if(Array.isArray(unknownObject)){
        return(
            unknownObject.map(element => {
                if (filter(element)){
                    return(
                        <Chip key={element} className="m-1" text={element} icon="fas fa-heart" selected={false} isClickable={false}/>  
                    )
                }else{
                    return(null)
                }
                
            })
        )
    }else if(typeof unknownObject === 'string'){
        try {
            let parsed = JSON.parse(unknownObject)
            return(
                typeToComponent(parsed)
            )
        } catch (error) {
            return(
                <Sub2 text={unknownObject} />
            )
        }
        
    }else if(typeof unknownObject === 'object'){
        var counter = 0;
        return(
            
            Object.keys(unknownObject).map(element => {
                counter += 1;
                return (
                    <div key={counter.toString}>
                    {
                        typeToComponent(element)
                    }
                    {
                        typeToComponent(unknownObject[element])
                    }
                    </div>
            )}
        )
        )
    }else if(typeof unknownObject === 'number'){
        return(
            unknownObject
        )
    }
}

/**
 * 
 * @param {content} param0 a dictionary where the keys are the titles
 * and the values are toe contents
 * @returns 
 */
export function AudienceCard({content, name, loading, number, setShowAdInfo}) {


        const mapping_values ={'conformity': "The audience believes that people should do what they are instructed. They believes that people should always follow rules, even when no one is watching. Breaking rules can attract the attention of the audience.",
        'tradition': "Traditions play a major role for the audience. Topics such as family and religion can attract attention. When communicating with the audience, it is important to present yourself as humble and modest. The target group does not appreciate it when someone attracts a lot of attention.",
        'charity': "Helping other people has a great proity for the audience. Social projects or people who help others are valued by the target group. At the same time, the target group is strongly activated by other people's problems.",
        'universalism': "The protection of nature and the environment plays a crucial role for the audience. At the same time, people belong to this audience find it exciting to deal with other opinions. New ideas and opinions receive a lot of attention from the audience.",
        'self-determined': "For the audience, individual freedom is paramount. At the same time, new ideas and concepts attract a lot of attention. When communicating with the audience, it is important to present original concepts in order to activate the target group as much as possible.",
        'adventure': "The audience loves adventure and is not afraid of taking risks. In communication, it is a good idea to play with surprises in order to stimulate the audience's motive for adventure. At the same time, the audience is activated by all the cues about adventure and risk.",
        'hedonism': "Joie de vivre and fun are decisive factors for the audience. The audience likes to indulge themselves. So they are particularly activated by luxury foods or vacations.In communication, it is therefore important to include elements that represent joie de vivre or enjoyment for the audience.",
        'achievement': "The audience has the goal to be very successful. Thus, all elements that are related to the success of the audience can activate them very much. At the same time, it is crucial for the audience that other people perceive their success. Therefore, communication should take these two aspects into account in order to reach the audience.",
        'power': "The audience wants to be respected by others. At the same time, people should follow their presence. Exerting influence and power on others plays an important role for this target group. In communication, aspects related to power, such as hierarchies, politics, competition, etc. should be played.",
        'security': "The target group wants to be in a safe environment. Topics that increase or endanger one's own safety can particularly activate the target group. In communication, the topic of safety can be used to attract the attention of the target group."
        }

    const labels = ['TV', 'Streaming', 'Social Networks', 'Newspapers & Magazines (offline)',  'Newspapers & Magazines (online)', 'Radio'];

    const d = {
    labels,
    datasets: [
        {
        label: 'Media use:',
        data: content["chartdata"]["media"],
        borderColor: '#C7481F',
        backgroundColor: '#C7481F',
        }
    ],
    };

    const [data, ] = React.useState(d)

    const options = {
        indexAxis: 'y',

        scales: {
            xAxes:[{
                ticks:{
                    fontFamily: '"Readex\\ Pro"'}
                }
                ],
            yAxes: [{
                display: false,
                ticks: {
                    suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                    stepSize: 10,
                    max: 100,
                    callback: function(value) {
                        return value + "%"
                    },
                    beginAtZero: true   // minimum value will be 0.
                }
            }]
        },

        responsive: true,
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                    return previousValue + currentValue;
                });
                var currentValue = dataset.data[tooltipItem.index];
                var percentage = Math.floor(((currentValue/total) * 100)+0.5);         
                return percentage + "%";
                }
            }
            }
      };
      
      const labels_media = ['\uf1a0','\uf09a','\uf16d','\uf099', '\uf2ab', '\uf0d2', '\uf1a1', '\uf167','\uf1e8', '\uf168', '\uf08c', '\ue07b'];
      const mapping = {'\uf1a0':"Google",'\uf09a':"Facebook",'\uf16d':"Instagram",'\uf099':"Twitter", '\uf2ab':"Snapchat", '\uf0d2':"Pintrest", '\uf1a1':"Reddit", '\uf167':"YouTube",'\uf1e8':"Twitch", '\uf168':"Xing", '\uf08c':"LinkedIn", '\ue07b':"TikTok"};

      const d_mm = {
        labels: labels_media,
        mapping : mapping,
        datasets: [
          {
          label: 'Plattform use:',
          data: content["chartdata"]["plattform"],
          borderColor: '#C7481F',
          backgroundColor: '#C7481F',
          }
      ],
      };

      const [data_media, ] = React.useState(d_mm)

      const options_media = {
          indexAxis: 'y',
  
          scales: {
              xAxes:[{
                  ticks:{
                      fontFamily: '"Font Awesome 5 Brands"'}
                  }
                  ],
              yAxes: [{
                  display: false,
                  ticks: {
                      suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                      stepSize: 10,
                      max: 100,
                      callback: function(value) {
                          return value + "%"
                      },
                      beginAtZero: true   // minimum value will be 0.
                  }
              }]
          },
  
          responsive: true,
          legend: {
              display: false
          },
          tooltips: {
              display: false,
              callbacks: {
                  label: function(data) {
                    console.log(data.label)
                    return mapping[data.label];
                    }
              }
              }
        };
        

    const [isLoading, setIsLoading] = React.useState(false)

    React.useEffect(() => {
        console.log("props.loading changed to " + loading);
        if(loading){
            setIsLoading(true);
        }else{
            setIsLoading(false);
        }
    }, [loading])

    React.useEffect(() => {
        console.log(data_media);
        
    }, [data_media])

    const cardFlipRef = React.useRef(null);

    const numberToChar = (n) => {
        return String.fromCharCode(n+65)
    }

    return (
        <FlipCard front={
            <div className="bg-white m-2">
                {/* isLoading ? <Spinner className="fixed top-64 left-0 w-full z-50 overflow-hidden flex flex-col items-center justify-center" /> : null */}

                <div className={`${isLoading ? "filter blur-sm" : ""} grid grid-cols-1 gap-2 divide-y divide-secondaryUltraLight bg-white max-w-sm rounded-lg overflow-hidden shadow-lg  border-secondary border-b-4 border-2`}>
                    <div className="p-4 flex flex-col">
                        <H1 position="text-left" text={"Target Group: " + numberToChar(number)} />
                        <div className="justify-between flex flex-row">
                            <Sub1 text={"Matching Score: " + Math.floor(50 + Math.random() * 50) + "%"} highlight={[2]} />
                            <ButtonInputSm color="primary" colorHover="secondaryLight" text="Show targeting"  onClick={ () => {cardFlipRef.current();submitEvent("audience_display","display_targetings") }} />
                        </div>
                    </div>
                    {
                        (typeof content === 'object') ?
                            Object.keys(content).map(value => {

                                if(value !== "targetings" && value !== "dummy_targetings" && value !== "chartdata"){
                                    return (
                                        <div key={value} className="p-4 flex-col flex items-start">
                                            <H2 text={value} />
                                            <div className="flex items-start flex-wrap">
                                                { typeToComponent(content[value]) }
                                            </div>
                                        </div>
                                    )
                                }else{
                                    return null
                                }
                            })
                        : null
                    }



                    <div className="p-4 flex-col flex items-start">
                        <H2 text="Media" />  
                        <div className="mt-4 w-full flex justify-center">
                            <Bar options={options} data={data} />          
                        </div>
                    
                            
                    </div>

                    <div className="p-4 flex-col flex items-start">
                        <H2 text="Plattform" />  
                        <div className="mt-4 w-full flex justify-center">
                            <Bar options={options_media} data={data_media} />  
                        </div>
                                               
                    </div>

                    
                        
                    {
                        Object.keys(content).map(value => {
                            var has_values = false;
                            if(value !== "targetings" && value !== "dummy_targetings" && value !== "chartdata"){
                                content[value].map(v => {
                                    if (mapping_values[v]){
                                        has_values = true
                                    }
                                    if(has_values){
                                        return true
                                    }else{
                                        return false
                                    }
                                })
                                
                            }
                            if (has_values){
                                return(
                                <div className="p-4 flex-col flex items-start">
                                <H2 text="Tipps for target group" />  

                                <div className="mt-1 w-full flex flex-col  justify-start">

                                        {(typeof content === 'object') ?
                                            Object.keys(content).map(value => {

                                                if(value !== "targetings" && value !== "dummy_targetings" && value !== "chartdata"){
                                                    return content[value].map(v => {
                                                        const value_des = mapping_values[v]
                                                    
                                                        if (value_des){
                                                            return (
                                                                <div className=""> 
                                                                <Sub2 key = {value_des} text={value_des} position={"text-left"}/>   
                                                                <div className="mb-2"/>
                                                                </div>    
                                                            )
                                                        }else{
                                                            return null
                                                        }
                                                        })
                                                                                            
                                                                                        
                                                }else{
                                                    return null
                                                } })
                                                : null
                                                }
                                    </div>
                                                                            
                                                                                    
                                </div>)
    
                            }else{
                                return(null)
                            }
                            
                        })
                        
                        
                    }
                    
                    <div className="p-5">
                        <ButtonInputMdIcon icon="fas fa-bullhorn" color="primary" colorHover="primaryLight" text="Advertise target group"  onClick={ () => {setShowAdInfo(true);submitEvent("audience_display","click_advertise")}} />
                    </div>   
                    
                </div>
                
            </div>}
            back={
                <div className="bg-white m-2">
                    {/* isLoading ? <Spinner className="fixed top-64 left-0 w-full z-50 overflow-hidden flex flex-col items-center justify-center" /> : null */}

                    <div className={`${isLoading ? "filter blur-sm" : ""} grid grid-cols-1 gap-2 divide-y divide-secondaryUltraLight bg-white max-w-sm rounded-lg overflow-hidden shadow-lg  border-secondary border-b-4 border-2`}>

                        <div className="p-4 flex flex-col">
                            <H1 position="text-left" text={"Target Group: " + numberToChar(number)} />
                            <div className="justify-between flex flex-row">
                                <Sub1 text={"Targeting List"} highlight={[0,1]} />
                                <ButtonInputSm color="secondary" colorHover="secondaryLight" text="Back"  onClick={ () => {cardFlipRef.current(); submitEvent("audience_display","back_display_audience")} } />
                            </div>
                        </div>
                        
                        {
                            (typeof content["targetings"] === 'object') ?
                                <div className="divide-y divide-secondaryUltraLight flex-col flex items-start">
                                    {
                                        Object.keys(content["targetings"]).map(platform => {
                                            return (
                                                <div key={platform} className="p-4 flex-col flex items-start w-full">
                                                    <H3 text={platform} />
                                                    <div className="flex items-start flex-wrap">
                                                        { content["targetings"][platform].map(targeting => {
                                                            return (
                                                                <Chip key={targeting} className="m-1" text={targeting} icon="fas fa-heart" selected={true} isClickable={false}/>  
                                                            )
                                                        }) }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    
                                </div>
                               
                            : null
                        }
                        {
                            typeof(content["dummy_targetings"]) === 'object' ?
                                <div className="divide-y divide-secondaryUltraLight flex-col flex items-start">
                                    {
                                        Object.keys(content["dummy_targetings"]).map(platform => {
                                            return (
                                                <div key={platform} className="p-4 flex-col flex items-start w-full">
                                                    <H3 text={platform} />
                                                    <div className="mt-2">
                                                        <div className="flex items-start flex-wrap filter blur-sm">
                                                            { content["dummy_targetings"][platform].map(targeting => {
                                                                return (
                                                                    <Chip className="m-1" key={targeting} text={targeting} icon="fas fa-heart" selected={true} isClickable={false}/>  
                                                                )
                                                            }) }
                                                        </div>
                                                        <ButtonInputMdIcon icon="fas fa-lock" color="primary" colorHover="primaryLight" text="Request Access"  onClick={ () => {document.location.href='/request';submitEvent("audience_display","request_acess")}} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            : null
                        }
                        

                    </div>
                    
                </div>
            }
            flipRef={cardFlipRef}

           
        />
    )
}