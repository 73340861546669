import { H1 } from '../../Components/BasicComponents/Text/H1';
import { Sub1 } from '../../Components/BasicComponents/Text/Sub1';
import persona_cards from '../../personas.svg';
import React from 'react';
import { HeaderNavigation } from '../../Components/UI/HeaderNavigation';
import { ButtonInputLg } from '../../Components/BasicComponents/Buttons/ButtonInputLg';
import { Sub2 } from '../../Components/BasicComponents/Text/Sub2';
import { Footer } from '../../Components/UI/Footer';
import { motion } from "framer-motion";

export default function Homepage(){

    const ellipseVariants = {
        start: {
          d:
           "m-2.93836,-1.69138l103.14478,0c-21.12834,30.36036 -35.41833,9.95623 -58.35418,24.18135c-15.23309,13.56984 -15.04231,29.66032 -45.07872,36.2198",
           scale: 1.0
        },
        end: {
          d:
        "m-2.93836,-1.69138l103.14478,0c-21.12834,30.36036 -23.70661,9.95623 -58.35418,38.59578c-15.23308,13.56984 -35.76304,-7.27666 -45.07872,21.80537",
        scale: 1.3
        }
      };

    return(
        <div>
            <div className="z-0 absolute h-full w-full left-0 top-0 lg:top-20 mr-60 overflow-hidden">
                    <motion.svg
                    initial="start"
                    width="600"
                    height="700"
                    viewBox="10 7 100 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    animate="end">
                        <motion.path
                            variants={ellipseVariants}
                            transition={{
                                duration: 4,
                                yoyo: Infinity,
                                repeat: Infinity
                                }}
                            fill="#C7481F"
                          
                            />
                    </motion.svg>
            </div>
            
            <HeaderNavigation className="z-20"/>
            
            <div className="flex flex-col justify-center w-full">
           
                <div className="flex justify-center z-10 mt-5 lg:mt-10">
                    <object type="image/svg+xml" data={persona_cards} aria-labelledby="label1" className="h-full w-full lg:h-3/6 lg:w-3/6"></object>
                </div>
   
                <div className="p-2">
                    <H1 className="z-20" text = "The simplest way to identify your audiences" position="text-center"/>
                    <div className="mt-2">
                        <Sub1 text = "Finding your target group as easy as swiping through images. Start now swiping - it's completly free!" highlight={[10,12]} position="text-center"/>
                    </div>
                </div>
                <div className="flex justify-center mt-10">
                    <ButtonInputLg onClick={() => {document.location.href='/app'}} text="Find audience" color = "secondary" colorHover ="secondaryLight"/>
                </div>
                <div className="flex justify-center mt-1">
                    <Sub2 text="No singup required"/>
                </div>
                
            </div>    
            
            <Footer/>

            
        </div>
    )
}