import React, { useState, useEffect } from 'react';
import { H1 } from '../../Components/BasicComponents/Text/H1';
import { H2 } from '../../Components/BasicComponents/Text/H2';
import { HeaderNavigation } from '../../Components/UI/HeaderNavigation';
import { Footer } from '../../Components/UI/Footer';
import { ButtonInputLgIcon } from '../../Components/BasicComponents/Buttons/ButtonInputLgIcon';
import { sendRequest } from '../../Components/Backend/Backend';

export default function RequestForm(){

    const [answer1, setAnswer1] = useState("");
    const [answer2, setAnswer2] = useState("");
    const [answer3, setAnswer3] = useState("");
    const [answer4, setAnswer4] = useState("");
    const [answer5, setAnswer5] = useState(false);
    const [showError, setShowError] = useState(false)
    const [sent, setSent] = useState(false)



    const [response, setResponse] = React.useState(null);
    //const [dummy, setDummy] = useState(false)
    const [, setDummy] = useState(false)

    const sendData = function(){
        if(answer5){
        var data = [answer1, answer2,answer3,answer4,answer5]

        let dataRequest = {
            "method": "POST",
            "url": "https://audiencefinder-backend-p7inyw7pqq-ey.a.run.app/request",
            "data": {
                "token": localStorage.getItem('token'),
                "data": data
            }
        }
        sendRequest(dataRequest, setDummy, setResponse);
    
        }else{
            setShowError(true)
        }
        
        
    }
    useEffect(() => {
        if(response){
            setSent(true)
        }
        
    }, [response])

    return(
        <div>
            <HeaderNavigation className="z-50"/>

            <div className="z-0 flex justify-center w-full">


                <div class="p-1 lg:p-8 bg-white mt-8 lg:mt-32 w-full lg:w-1/2 m-2 lg:m-0 lg:w-1/2 h-2/3 grid grid-cols-1 gap-2 divide-secondaryUltraLight rounded-lg overflow-hidden shadow-lg border-secondary border-b-4 border-2">
                       
                   <div>
                        <H1 text = "Request Access"/>
                        
                        <div className="mt-8"/>
                            {sent ? 
                            <div className="mt-16 flex flex-col items-center justify-center">
                                <i className="text-secondary text-4xl fas fa-unlock mb-4"></i>
                                <H2 text = "Thank you for your interest in AudienceDeck additional features!! As soon as we can give you access we will contact you."/>
                            </div>
                             :
                            <div>
                                <div>
                                    <label for="comment" class="block text-sm font-medium text-secondary">1.How would you describe yourself in one sentence?</label>
                                    <div class="mt-1">
                                        <textarea onChange={(e)=> {setAnswer1(e.target.value)}} value={answer1} rows="4" name="comment" id="comment" class=" ring-transparent shadow-sm ring-2 ring-primary focus:ring-primary focus:border-primary block w-full text-2sm font-thin text-secondary p-1 rounded-md"></textarea>
                                    </div>
                                </div>
                                <div className="mt-4"/>
                                <div>
                                    <label for="comment" class="block text-sm font-medium text-secondary">2. Why are you interested in the topic of target groups?</label>
                                    <div class="mt-1">
                                        <textarea onChange={(e)=> {setAnswer2(e.target.value)}} value={answer2} rows="4" name="comment" id="comment" class=" ring-transparent shadow-sm ring-2 ring-primary focus:ring-primary focus:border-primary block w-full text-2sm font-thin text-secondary p-1 rounded-md"></textarea>
                                    </div>
                                </div>
                                <div className="mt-4"/>
                                <div>
                                    <label for="comment" class="block text-sm font-medium text-secondary">3. Why do you want access for additional features?</label>
                                    <div class="mt-1">
                                        <textarea onChange={(e)=> {setAnswer3(e.target.value)}} value={answer3} rows="4" name="comment" id="comment" class=" ring-transparent shadow-sm ring-2 ring-primary focus:ring-primary focus:border-primary block w-full text-2sm font-thin text-secondary p-1 rounded-md"></textarea>
                                </div>
                                </div>
                                <div class="mt-8">
                                    <label class="block text-sm font-medium text-secondary" for="name">
                                        Mail
                                    </label>
                                    <input onChange={(e)=> {setAnswer4(e.target.value)}} value={answer4} class="text-2sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight ring-2 ring-primary focus:outline-none focus:shadow-outline" id="mail" type="text" placeholder="info@example.com"/>
                                </div>
                                <div class="mt-4 relative flex items-start">
                                    <div class="flex items-center h-5">
                                        <input onChange={(e)=> {setAnswer5(e.target.value)}} value={answer5} id="dataprocted" aria-describedby="offers-description" name="offers" type="checkbox" class="focus:ring-primary h-4 w-4 text-secondary border-primary rounded"/>
                                    </div>
                                    <div class="ml-3 text-sm">
                                        <label for="offers" class="block text-2sm font-thin text-secondary">I agree that my data will be used to process your access request. Further information and revocation options can be found in the <a className="underline" href="/privacy">data protection declaration</a>.</label>
                                        {showError?
                                        <label for="offers" class="block text-4sm font-thin text-red-500">You have to accept it to send a request</label>
                                        : "" }
                                   </div>
                                </div>
      
                                <div className="w-full flex justify-end mr-4 mt-10">
                                    <ButtonInputLgIcon onClick={sendData} text ="Send Feedback" icon="fas fa-paper-plane" color ="secondary"/>
                                </div>
                            </div>
                            }
                            
                            
                    </div>
                
                </div>    

            </div>
                
            <Footer/>         

        </div>
    )
}