import React from 'react';

export function TextInput(props) {


    const onChangeValue = (e) => {
        props.onChangeValue(props.saveId,e.target.value);
    }
    
    return (
        <div className="m-2">
            <label htmlFor={props.name} className="block text-sm font-medium text-gray-700">{props.name}</label>
            <div className="mt-1">
                <input onChange={onChangeValue} type={props.type === undefined ? "text" :props.type} name={props.name} id={props.name} className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-primaryLight rounded-md" placeholder={props.placeholder}/>
            </div>
        </div>
    );
  }